import React, { useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

const CollapsibleSidebar = ({
  children,
  footer,
  title,
  position = "left",
  initialState = false,
}) => {
  const [isOpen, setIsOpen] = useState(initialState);

  const toggleSidebar = () => setIsOpen(!isOpen);

  const OpenChevronIcon =
    position === "left" ? ChevronLeftIcon : ChevronRightIcon;
  const ClosedChevronIcon =
    position === "left" ? ChevronRightIcon : ChevronLeftIcon;

  return (
    <div
      className={`absolute ${position}-0 top-0 h-full z-30 transition-all duration-300 ${
        isOpen ? "w-64" : "w-0"
      }`}
    >
      <div
        className={`relative h-full bg-white shadow-md flex flex-col ${
          isOpen ? "w-64" : "w-0"
        } overflow-hidden transition-all duration-300`}
      >
        {isOpen && (
          <>
            <div className="flex items-center justify-evenly p-4 border-b">
              {position === "left" ? (
                <>
                  <h3 className="text-xl font-bold text-gray-800">{title}</h3>
                  <button
                    onClick={toggleSidebar}
                    className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                  >
                    <OpenChevronIcon className="w-6 h-6" />
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={toggleSidebar}
                    className="p-2 rounded-full hover:bg-gray-200 transition-colors duration-200"
                  >
                    <OpenChevronIcon className="w-6 h-6" />
                  </button>
                  <h3 className="text-xl font-bold text-gray-800">{title}</h3>
                </>
              )}
            </div>
            <div className="flex-grow overflow-y-auto p-4">{children}</div>
            {footer && <div className="p-4 border-t">{footer}</div>}
          </>
        )}
      </div>
    </div>
  );
};

export default CollapsibleSidebar;
