import { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useStory } from "../context/StoryContext";
import { getCoverImage } from "utils/bookHelpers";

export const useBookPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [userPhotosRequestId, setuserPhotosRequestId] = useState(null);

  const { setCurrentStoryId, storyState, dispatch } = useStory();
  const isCoverPage = storyState.currentPage === 0;
  console.log("STORY STATE IN useBookPage", storyState);
  useEffect(() => {
    if (id && id !== storyState.id) {
      setCurrentStoryId(id);
    }
  }, [id, storyState.id, setCurrentStoryId]);

  const getCurrentPageFaceSize = () => {
    const currentPage = storyState.currentPage;
    if (currentPage === 0) {
      const frontCover = storyState.coverImages.find((img) => img.is_default);
      return frontCover?.faceSize || "";
    }
    return storyState.pages[currentPage - 1]?.faceSize || "";
  };

  const handlePageChange = (newPage) => {
    console.log("useBookPage - handlePageChange called with:", newPage);
    dispatch({
      type: "UPDATE_PAGE_NUMBER",
      payload: { pageNumber: newPage },
    });
  };

  const handleFaceSizeChange = (faceSize) => {
    dispatch({
      type: "UPDATE_FACE_SIZE",
      payload: { pageNumber: storyState.currentPage, faceSize },
    });
  };

  const handleFacePositioninChange = (facePositioning) => {
    dispatch({
      type: "UPDATE_FACE_POSITION",
      payload: { pageNumber: storyState.currentPage, facePositioning },
    });
  };

  const handleAngleRatioChange = (angleRatio) => {
    dispatch({
      type: "UPDATE_ANGLE_RATIO",
      payload: { pageNumber: storyState.currentPage, angleRatio },
    });
  };

  const handleControlnetStrengthChange = (controlnetStrength) => {
    dispatch({
      type: "UPDATE_CONTROLNET_STRENGTH",
      payload: { pageNumber: storyState.currentPage, controlnetStrength },
    });
  };

  const handleNewSeedToggled = () => {
    dispatch({
      type: "TOGGLE_NEW_SEED",
      payload: { pageNumber: storyState.currentPage },
    });
  };

  const handlePromptChange = useCallback(
    (newPrompt) => {
      dispatch({
        type: "UPDATE_USER_PROMPT",
        payload: { pageNumber: storyState.currentPage, userPrompt: newPrompt },
      });
    },
    [dispatch, storyState.currentPage]
  );

  const getCurrentPagePrompt = useCallback(() => {
    if (storyState.currentPage === 0) {
      const defaultCover = getCoverImage(storyState);
      return (
        defaultCover?.userPrompt || storyState.coverImages[0]?.userPrompt || ""
      );
    }
    return storyState.pages?.[storyState.currentPage - 1]?.userPrompt || "";
  }, [storyState]);

  return {
    storyState,
    isCoverPage,
    userPhotosRequestId,
    navigate,
    getCurrentPageFaceSize,
    handlePromptChange,
    getCurrentPagePrompt,
    handleNewSeedToggled,
    handlePageChange,
    handleFaceSizeChange,
    handleFacePositioninChange,
    handleAngleRatioChange,
    handleControlnetStrengthChange,
    setuserPhotosRequestId,
  };
};
