// src/pages/NewBookPage.jsx
import React, { useMemo, useEffect, useState } from "react";
import CollapsibleSidebar from "../components/CollapsibleSidebar";
import Book from "../components/Book";
import { useBook } from "hooks/useBook";
import ActionButtons from "../components/ActionButtons";
import { useAuth } from "../context/AuthContext";
import {
  BookGenerationSidebar,
  BookControlsSidebar,
  BookSidebar,
} from "../components/sidebars/BookSidebar";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`;

const NewBookPage = () => {
  const { id, timestamp, hash } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { user, loading } = useAuth();
  const [coverGenerationStatus, setCoverGenerationStatus] = useState(
    location.state?.generationStatus || "completed"
  );
  const [pollingInterval, setPollingInterval] = useState(null);

  const {
    sidebarState,
    bookDisplayState,
    generationState,
    controlsState,
    handlePageChange,
    handleImageClick,
    storyState,
  } = useBook();

  useEffect(() => {
    // If we're in generation state, start polling for status
    if (location.state?.generationStatus === "generating") {
      const interval = setInterval(async () => {
        try {
          const response = await fetch(
            `${base_backend}/image/status/${location.state.requestId}`
          );
          const data = await response.json();

          if (data.data.status === "COMPLETED") {
            setCoverGenerationStatus("completed");
            clearInterval(interval);
            // Refresh the page to show the new cover
            window.location.reload();
          } else if (data.data.status === "ERROR") {
            setCoverGenerationStatus("error");
            clearInterval(interval);
          }
        } catch (error) {
          console.error("Error polling generation status:", error);
        }
      }, 5000); // Poll every 5 seconds

      setPollingInterval(interval);
    }

    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [location.state?.generationStatus]);

  const generatePreviewLink = async () => {
    try {
      const response = await axios.post(`/api/story/preview-link/${id}`);
      const { previewUrl } = response.data;
      // Get the full URL including domain
      const fullUrl = `${window.location.origin}${previewUrl}`;
      return fullUrl;
    } catch (error) {
      console.error("Error generating preview link:", error);
      throw error;
    }
  };

  const handlePurchaseSuccess = () => {
    // Handle successful purchase
    // For example, show a success message or redirect
  };

  // Define hooks before any conditional logic
  const renderSidebar = useMemo(
    () => (position, title, content, actions) =>
      (
        <CollapsibleSidebar
          position={position}
          title={title}
          footer={<ActionButtons actions={actions} />}
        >
          {content}
        </CollapsibleSidebar>
      ),
    []
  );

  const sidebar = useMemo(() => {
    return (
      <CollapsibleSidebar
        position="left"
        title="Book Editor"
        initialState={true}
      >
        <BookSidebar
          generationState={generationState}
          controlsState={controlsState}
          sidebarState={sidebarState}
          storyState={storyState}
          handlePurchaseSuccess={handlePurchaseSuccess}
          limitedAccess={!user?.authenticated || !storyState?.is_paid}
          user={user}
        />
      </CollapsibleSidebar>
    );
  }, [
    user,
    sidebarState,
    generationState,
    controlsState,
    storyState,
    handlePurchaseSuccess,
  ]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="relative flex w-full h-full bg-gray-50">
      {coverGenerationStatus === "generating" && (
        <div className="fixed top-0 left-0 right-0 bg-blue-100 p-4 text-center z-50">
          <div className="flex items-center justify-center space-x-2">
            <svg
              className="animate-spin h-5 w-5 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <span className="text-blue-700">
              Generating your personalized book cover...
            </span>
          </div>
        </div>
      )}
      <div className="grow flex overflow-hidden">
        <Book
          {...bookDisplayState}
          story={storyState}
          viewMode={
            storyState?.imageLayout || storyState?.settings?.imageLayout
          }
          isEditable={user?.authenticated}
          onPageChange={handlePageChange}
          onImageClick={handleImageClick}
        />
      </div>
      {sidebar}
    </div>
  );
};

export default NewBookPage;
