import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";
import logoImage from "../assets/logo.png";

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  const handleLogout = () => {
    logout();
    navigate("/", { replace: true });
  };

  const NavLink = ({ to, children, onClick }) => (
    <Link
      to={to}
      className="block text-pastel-blue-800 hover:text-pastel-blue-600 px-4 py-3 rounded-md text-base font-medium"
      onClick={onClick}
    >
      {children}
    </Link>
  );

  const closeMenu = () => setIsMenuOpen(false);

  return (
    <nav
      className={`bg-pastel-white shadow-md absolute top-0 left-0 right-0 z-50 h-20 transition-transform duration-300 ${
        visible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      <div className="max-w-full mx-auto px-4 sm:px-6 lg:px-8 h-full">
        <div className="flex items-center justify-between h-full">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              <Link to="/">
                <img className="h-10 w-auto" src={logoImage} alt="Quill Logo" />
              </Link>
            </div>
          </div>
          <div className="hidden md:block flex-grow">
            <div className="ml-10 flex items-center justify-end space-x-6">
              <NavLink to="/about">About</NavLink>
              <NavLink to="/contact">Contact</NavLink>
              {user ? (
                <>
                  <NavLink to="/home">Home</NavLink>
                  <NavLink to="/profile">Profile</NavLink>
                  <button
                    onClick={handleLogout}
                    className="text-pastel-blue-800 hover:text-pastel-blue-600 px-4 py-3 rounded-md text-base font-medium"
                  >
                    Logout
                  </button>
                </>
              ) : (
                <>
                  <NavLink to="/login">Login</NavLink>
                  {/* <NavLink to="/signup">Sign Up</NavLink> */}
                </>
              )}
            </div>
          </div>
          <div className="md:hidden">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-pastel-blue-600 hover:text-pastel-blue-800 hover:bg-pastel-blue-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pastel-blue-600"
            >
              <span className="sr-only">Open main menu</span>
              {isMenuOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {isMenuOpen && (
        <div className="md:hidden absolute top-20 left-0 right-0 bg-pastel-white shadow-md">
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <NavLink to="/about" onClick={closeMenu}>
              About
            </NavLink>
            <NavLink to="/contact" onClick={closeMenu}>
              Contact
            </NavLink>
            {user ? (
              <>
                <NavLink to="/home" onClick={closeMenu}>
                  Home
                </NavLink>
                <NavLink to="/profile" onClick={closeMenu}>
                  Profile
                </NavLink>
                <button
                  onClick={() => {
                    handleLogout();
                    closeMenu();
                  }}
                  className="block w-full text-left text-pastel-blue-800 hover:text-pastel-blue-600 px-4 py-3 rounded-md text-base font-medium"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <NavLink to="/login" onClick={closeMenu}>
                  Login
                </NavLink>
                <NavLink to="/signup" onClick={closeMenu}>
                  Sign Up
                </NavLink>
              </>
            )}
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
