import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import {
  setAccessToken,
  removeAccessToken,
  setRefreshToken,
  removeRefreshToken,
  getAccessToken,
  getRefreshToken,
  refreshAccessToken,
} from "../utils/auth";

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadUser = () => {
      try {
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();
        const userId = localStorage.getItem("userId");
        const userEmail = localStorage.getItem("userEmail");

        if (accessToken && refreshToken && userId && userEmail) {
          setUser({
            id: userId,
            email: userEmail,
            accessToken,
            refreshToken,
            authenticated: true,
          });
        } else {
          removeAccessToken();
          removeRefreshToken();
          localStorage.removeItem("userId");
          localStorage.removeItem("userEmail");
          localStorage.removeItem("authenticated");
          setUser(null);
        }
      } catch (error) {
        console.error("Error loading user:", error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    loadUser();
  }, []);

  const login = useCallback(
    (accessToken, refreshToken, userId, email, authenticated) => {
      console.log("Login called with:", {
        accessToken,
        refreshToken,
        userId,
        email,
        authenticated,
      });

      if (!accessToken || !refreshToken || !userId || !email) {
        console.error("Missing required login data");
        setUser(null);
        return;
      }

      try {
        setAccessToken(accessToken);
        setRefreshToken(refreshToken);

        localStorage.setItem("userId", userId);
        localStorage.setItem("userEmail", email);
        localStorage.setItem("authenticated", "true");

        const userData = {
          id: userId,
          email,
          accessToken,
          refreshToken,
          authenticated: true,
        };

        setUser(userData);
        console.log("User logged in successfully:", userData);
      } catch (error) {
        console.error("Error during login:", error);
        setUser(null);
      }
    },
    []
  );

  const logout = useCallback(() => {
    removeAccessToken();
    removeRefreshToken();
    localStorage.removeItem("userId");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("authenticated");
    setUser(null);
  }, []);

  const updateAccessToken = useCallback((newToken) => {
    setAccessToken(newToken);
    setUser((prevUser) => ({
      ...prevUser,
      accessToken: newToken,
    }));
    console.log("AuthProvider - Access token updated");
  }, []);

  const refreshToken = useCallback(async () => {
    const currentRefreshToken = getRefreshToken();
    if (currentRefreshToken) {
      try {
        const newAccessToken = await refreshAccessToken(currentRefreshToken);
        if (newAccessToken) {
          updateAccessToken(newAccessToken);
          return newAccessToken;
        }
      } catch (error) {
        console.error("Failed to refresh token:", error);
        logout();
      }
    }
    return null;
  }, [updateAccessToken, logout]);

  const value = {
    user,
    login,
    logout,
    loading,
    updateAccessToken,
    refreshToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
