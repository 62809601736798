// src/context/StoryContext.js

import React, { createContext, useContext } from "react";
import useStoryManager from "../hooks/useStoryManager";

const StoryContext = createContext(null); // Provided default value

export const StoryProvider = ({ children }) => {
  const storyManager = useStoryManager();

  if (!storyManager) {
    return <div>Error initializing story manager</div>;
  }

  return (
    <StoryContext.Provider value={storyManager}>
      {children}
    </StoryContext.Provider>
  );
};

export const useStory = () => {
  const context = useContext(StoryContext);
  if (context === null) {
    throw new Error("useStory must be used within a StoryProvider");
  }
  return context;
};
