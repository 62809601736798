// src/components/sidebars/BookSidebar.jsx
import React from "react";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  StarIcon,
  TrashIcon,
  PencilIcon,
  BookOpenIcon,
  SwatchIcon,
} from "@heroicons/react/24/outline";
import { Button, IconButton, Checkbox, Slider } from "../uicomponents";
import PurchaseButton from "../PurchaseButton";

const CollapsibleSection = ({ title, children, defaultOpen = false }) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  return (
    <div className="border-b border-gray-200 last:border-b-0">
      <button
        className="w-full px-4 py-3 flex justify-between items-center hover:bg-gray-50"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="font-medium text-gray-900">{title}</h3>
        <ChevronRightIcon
          className={`w-5 h-5 transform transition-transform ${
            isOpen ? "rotate-90" : ""
          }`}
        />
      </button>
      {isOpen && <div className="p-4">{children}</div>}
    </div>
  );
};

export const BookSidebar = ({
  generationState,
  controlsState,
  sidebarState,
  storyState,
  handlePurchaseSuccess,
  limitedAccess,
  user,
}) => {
  const currentImage =
    controlsState.sortedRightImages[controlsState.selectedImageIndex];
  // Don't convert the value since it's already in the correct scale
  const initialSliderValue = currentImage?.faceSize || 1;

  const [sliderValue, setSliderValue] = React.useState(initialSliderValue);
  const [deleteOriginal, setDeleteOriginal] = React.useState(false);

  // Update slider when image changes without conversion
  React.useEffect(() => {
    if (currentImage?.faceSize) {
      setSliderValue(Math.min(Math.max(currentImage.faceSize, 0.5), 1.5));
    }
  }, [controlsState.selectedImageIndex, controlsState.sortedRightImages]);

  return (
    <div className="h-full flex flex-col p-1 space-y-6">
      {controlsState.sortedRightImages?.length > 0 && (
        <>
          {/* Image Navigation - Always visible */}
          <p className="text-sm text-gray-600">
            {
              controlsState.sortedRightImages[controlsState.selectedImageIndex]
                ?.filename
            }
          </p>
          <div className="space-y-4">
            <h3 className="font-medium text-gray-900 mb-2">Image Navigation</h3>
            <ImageNavigation
              selectedImageIndex={controlsState.selectedImageIndex}
              totalImages={controlsState.sortedRightImages.length}
              handleImageNavigation={controlsState.handleImageNavigation}
              handleSetDefaultImage={controlsState.handleSetDefaultImage}
              handleDeleteImage={controlsState.handleDeleteImage}
            />
          </div>

          {/* Image Prompt - Always visible */}
          <div className="space-y-4">
            <h3 className="font-medium text-gray-900 mb-2">Image Prompt</h3>
            <ImagePromptEditor
              currentPrompt={generationState.getCurrentPagePrompt()}
              handlePromptChange={generationState.handlePromptChange}
              imagePrompt={
                controlsState.sortedRightImages[
                  controlsState.selectedImageIndex
                ]?.imagePrompt
              }
            />
          </div>

          {/* Face Adjustments - Collapsible */}
          <CollapsibleSection title="Face Adjustments">
            <FaceAdjustments
              selectedImageIndex={controlsState.selectedImageIndex}
              handleAdjustImage={controlsState.handleAdjustImage}
              isAdjusting={controlsState.isAdjusting}
              showButton={false}
              sliderValue={sliderValue}
              setSliderValue={setSliderValue}
              deleteOriginal={deleteOriginal}
              setDeleteOriginal={setDeleteOriginal}
            />
          </CollapsibleSection>

          {/* Generate Adjusted Button - Outside of Face Adjustments */}
          <Button
            onClick={() =>
              controlsState.handleAdjustImage(
                sliderValue,
                controlsState.selectedImageIndex,
                deleteOriginal
              )
            }
            fullWidth
            className="bg-green-500 hover:bg-green-600 text-white"
            disabled={controlsState.isAdjusting}
          >
            Generate Image
          </Button>

          {/* Add Color Correct Button */}
          <Button
            onClick={sidebarState.handleColorCorrect}
            fullWidth
            className="bg-purple-500 hover:bg-purple-600 text-white"
            disabled={sidebarState.isColorCorrecting}
            icon={SwatchIcon}
          >
            {sidebarState.isColorCorrecting ? "Correcting..." : "Color Correct"}
          </Button>
        </>
      )}

      {/* Show purchase section at the bottom if not paid */}
      {!storyState?.is_paid && (
        <div className="mt-auto border-t pt-4">
          <PurchaseButton
            bookId={storyState?.bookId}
            email={storyState?.email}
            onSuccess={handlePurchaseSuccess}
          />
        </div>
      )}
    </div>
  );
};

const ImagePromptEditor = ({
  currentPrompt,
  handlePromptChange,
  imagePrompt,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const prompt = currentPrompt || imagePrompt;

  return (
    <div className="space-y-4">
      {isEditing ? (
        <div className="space-y-2">
          <textarea
            value={prompt}
            onChange={(e) => handlePromptChange(e.target.value)}
            className="w-full p-2 border rounded-md"
            rows={5}
            autoFocus
          />
          <Button
            onClick={() => setIsEditing(false)}
            className="bg-blue-500 hover:bg-blue-600 text-white"
          >
            Done
          </Button>
        </div>
      ) : (
        <div
          className="group relative bg-gray-100 p-3 rounded-md cursor-pointer hover:bg-gray-200"
          onClick={() => setIsEditing(true)}
        >
          <p className="pr-8">{prompt || "No prompt available"}</p>
          <PencilIcon className="w-5 h-5 absolute top-3 right-3" />
        </div>
      )}
    </div>
  );
};

const ImageNavigation = ({
  selectedImageIndex,
  totalImages,
  handleImageNavigation,
  handleSetDefaultImage,
  handleDeleteImage,
}) => (
  <div className="space-y-4">
    <div className="flex justify-between items-center">
      <IconButton
        onClick={() => handleImageNavigation("prev")}
        icon={ChevronLeftIcon}
        disabled={selectedImageIndex === 0}
      />
      <span className="text-lg font-semibold">
        {selectedImageIndex + 1} / {totalImages}
      </span>
      <IconButton
        onClick={() => handleImageNavigation("next")}
        icon={ChevronRightIcon}
        disabled={selectedImageIndex === totalImages - 1}
      />
    </div>

    <div className="flex space-x-2">
      {totalImages > 1 && (
        <Button
          onClick={handleSetDefaultImage}
          disabled={selectedImageIndex === 0}
          icon={StarIcon}
          fullWidth
          className="bg-blue-500 hover:bg-blue-600 text-white"
        >
          Select
        </Button>
      )}
      <Button
        onClick={handleDeleteImage}
        icon={TrashIcon}
        className="bg-red-500 hover:bg-red-600 text-white"
      >
        Delete
      </Button>
    </div>
  </div>
);

const FaceAdjustments = ({
  selectedImageIndex,
  handleAdjustImage,
  isAdjusting,
  showButton = true,
  sliderValue,
  setSliderValue,
  deleteOriginal,
  setDeleteOriginal,
}) => {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium mb-2">Face Size</label>
        <Slider
          min={0.5}
          max={1.5}
          step={0.05}
          value={sliderValue}
          onChange={setSliderValue}
          marks={{
            0.5: "0.5",
            1: "1.0",
            1.5: "1.5",
          }}
        />
      </div>

      <Checkbox
        id="deleteOriginal"
        checked={deleteOriginal}
        onChange={(checked) => setDeleteOriginal(checked)}
        label="Delete original after generation"
      />

      {showButton && (
        <Button
          onClick={() =>
            handleAdjustImage(sliderValue, selectedImageIndex, deleteOriginal)
          }
          fullWidth
          className="bg-green-500 hover:bg-green-600 text-white"
          disabled={isAdjusting}
        >
          Generate Adjusted Image
        </Button>
      )}
    </div>
  );
};

const BookControlsPreview = ({
  storyState,
  handlePurchaseSuccess,
  limitedAccess,
}) => (
  <>
    <div className="border-t pt-6">
      {storyState?.is_paid ? (
        <div className="space-y-4">
          <div className="text-center p-4 bg-green-50 rounded-lg">
            <h3 className="font-bold text-green-800">
              Thank You For Your Purchase!
            </h3>
            {storyState.generation_status?.is_complete ? (
              <p className="text-sm text-green-600 mt-2">
                Your full book has been generated and is ready for printing!
              </p>
            ) : (
              <>
                <p className="text-sm text-green-600 mt-2">
                  We're generating the full version of your book with
                  high-quality images.{" "}
                  {storyState.generation_status?.completed || 0} of{" "}
                  {storyState.generation_status?.total || 0} pages completed.
                </p>
                <div className="w-full bg-gray-200 rounded-full h-2.5 mt-2">
                  <div
                    className="bg-green-600 h-2.5 rounded-full"
                    style={{
                      width: `${storyState.generation_status?.progress || 0}%`,
                    }}
                  ></div>
                </div>
              </>
            )}
          </div>
          <div className="bg-blue-50 p-4 rounded-lg">
            <h4 className="font-semibold text-blue-800 mb-2">
              Full Editor Access Unlocked
            </h4>
            <p className="text-sm text-blue-600">
              While we prepare your high-quality print version, you have access
              to all editing features. Feel free to continue customizing your
              book.
            </p>
            <ul className="list-disc list-inside text-sm text-blue-600 mt-2">
              <li>Generate custom images</li>
              <li>Edit text and layout</li>
              <li>Preview all pages</li>
              <li>Save your changes</li>
            </ul>
          </div>
        </div>
      ) : (
        <>
          <p className="mb-4">
            Purchase book to generate the rest of the pages.
          </p>
          {storyState?.bookId ? (
            <PurchaseButton
              bookId={storyState.bookId}
              email={storyState.email}
              onSuccess={handlePurchaseSuccess}
            />
          ) : (
            <div>Loading book details...</div>
          )}
        </>
      )}
    </div>
  </>
);
