import config from "./config";

export const getSortedImages = (images = []) => {
  if (!images.length) return [];
  return [
    ...images.filter((image) => image.is_default),
    ...images.filter((image) => !image.is_default),
  ];
};

export const findPageByNumber = (pages = [], pageNumber) => {
  return pages.find((p) => p.pageNumber === pageNumber) || null;
};

export const getPages = (storyState) => {
  if (storyState.currentPage === 0) {
    return {
      leftPage: { images: storyState.coverImages },
      rightPage: { images: storyState.coverImages },
    };
  }

  const left = findPageByNumber(storyState.pages, storyState.currentPage);
  const right = findPageByNumber(storyState.pages, storyState.currentPage + 1);

  return {
    leftPage: left || { images: [] },
    rightPage: right || { images: [] },
  };
};

export const apiRequest = async (endpoint, method = "POST", body = null) => {
  const url = `${config.apiUrl}/api/${endpoint}`;
  const response = await fetch(url, {
    method,
    headers: { "Content-Type": "application/json" },
    ...(body ? { body: JSON.stringify(body) } : {}),
  });

  if (!response.ok) {
    throw new Error(
      `Request to ${endpoint} failed (status: ${response.status}).`
    );
  }
  return response;
};

export const withLoading = async (setLoadingFn, asyncOperation) => {
  setLoadingFn(true);
  try {
    await asyncOperation();
  } finally {
    setLoadingFn(false);
  }
};

export const parsePromptNumbers = (prompt) => {
  const faceSize = parseFloat(prompt.faceSize);
  const facePositioning = parseFloat(prompt.facePositioning);
  const angleRatio = parseFloat(prompt.angleRatio);
  const controlnetStrength = parseFloat(prompt.controlnetStrength);

  return {
    ...prompt,
    faceSize:
      !isNaN(faceSize) && faceSize >= 0 && faceSize <= 100 ? faceSize : null,
    facePositioning:
      !isNaN(facePositioning) && facePositioning >= 0 && facePositioning <= 100
        ? facePositioning
        : null,
    angleRatio: !isNaN(angleRatio) && angleRatio <= 100 ? angleRatio : null,
    controlnetStrength:
      !isNaN(controlnetStrength) && controlnetStrength <= 100
        ? controlnetStrength
        : null,
  };
};

export const getCoverImage = (storyState) => {
  return (
    storyState.coverImages.find((img) => img.is_default) ||
    storyState.coverImages[0] ||
    null
  );
};

export const getImagesByMode = ({
  storyState,
  generateMode,
  selectedImages,
  filterFn,
  buildImageData,
}) => {
  if (generateMode === "selected") {
    return Object.values(selectedImages)
      .map(({ pageNumber }) => buildImageData(pageNumber))
      .filter(Boolean);
  } else {
    const pages = filterFn
      ? storyState.pages.filter(filterFn)
      : storyState.pages;

    const coverData = buildImageData(0);
    const pageDataList = pages
      .map((page) => buildImageData(page.pageNumber))
      .filter(Boolean);
    return coverData ? [coverData, ...pageDataList] : pageDataList;
  }
};
