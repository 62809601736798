import React, { useState, useEffect } from "react";
import { sections } from "../data/TemplateSteps";
import PhotoSelectionModal from "./PhotoSelectionModal";

const getImageLayoutDescription = (layout) => {
  if (layout === "single") {
    return "Single-page image";
  } else if (layout === "double") {
    return "Double-page image";
  }
  return null;
};

const normalizeSettings = (settings) => {
  return {
    characterName: settings.characterName || settings.character_name || "",
    age: settings.age || settings.character_age || "",
    gender: settings.gender || "",
    theme: settings.theme || "",
    challenge: settings.challenge || settings.type_of_conflict || "",
    setting: settings.setting || "",
    ageGroup: settings.ageGroup || settings.age_group || "",
    length: settings.length || "",
    narrativeRepetition:
      settings.narrativeRepetition || settings.narrative_repetition || false,
    characterRepetition:
      settings.characterRepetition || settings.character_repetition || false,
    rhyme: settings.rhyme || false,
    userPhotosRequestId:
      settings.userPhotosRequestId || settings.user_photos_request_id || null,
    imageLayout:
      getImageLayoutDescription(
        settings.imageLayout || settings.image_layout
      ) || null,
    storyPrompt: settings.storyPrompt || settings.quick_start_prompt || "",
  };
};

const TemplateCard = ({
  onWriteStory,
  initialSettings,
  isOpen,
  onClose,
  renderAsModal = true,
}) => {
  const [showModal, setShowModal] = useState(isOpen);
  const [currentStep, setCurrentStep] = useState(0);
  const [settings, setSettings] = useState(normalizeSettings(initialSettings));
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [showImageWarning, setShowImageWarning] = useState(false);
  const [photoSelectionModalOpen, setPhotoSelectionModalOpen] = useState(false);

  useEffect(() => {
    setShowModal(isOpen);
  }, [isOpen]);

  useEffect(() => {
    setSettings(normalizeSettings(initialSettings));
  }, [initialSettings]);

  const handleGenerate = () => {
    if (!settings.userPhotosRequestId) {
      setShowImageWarning(true);
      return;
    }

    if (renderAsModal) {
      setShowModal(false);
      onClose();
    }
    onWriteStory(settings);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSettings({
      ...settings,
      [name]:
        type === "checkbox"
          ? checked
          : type === "number"
          ? parseInt(value, 10)
          : value,
    });
  };

  const handlePhotoSelected = (requestId) => {
    setSettings({
      ...settings,
      userPhotosRequestId: requestId,
    });
    setIsImageSelected(true);
    setShowImageWarning(false);
  };

  const handleDropdownChange = (e, step) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      [name]: value === "custom" ? "" : value,
    });
  };

  const handleTextDropdownInputChange = (e, step) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      [name]: value,
    });
  };

  const handleNextStep = () => {
    if (currentStep < sections.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const setuserPhotosRequestId = (requestId) => {
    setSettings({
      ...settings,
      userPhotosRequestId: requestId,
    });
    setIsImageSelected(true);
    setShowImageWarning(false);
  };

  const openPhotoSelectionModal = () => {
    setPhotoSelectionModalOpen(true);
  };

  const closePhotoSelectionModal = () => {
    setPhotoSelectionModalOpen(false);
  };

  const renderContent = () => (
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">
        Book Settings
      </h2>
      <div className="mb-6 max-h-[60vh] overflow-y-auto">
        <h3 className="text-xl font-semibold mb-4 text-gray-700">
          {sections[currentStep].title}
        </h3>
        <p className="text-sm text-gray-500 mb-4">
          Step {currentStep + 1} of {sections.length}
        </p>
        {sections[currentStep].steps.map((step) => (
          <div key={step.label} className="mb-4">
            <label className="block text-gray-700 font-medium mb-2">
              {step.label}{" "}
              {step.name === "userPhotosRequestId" && (
                <span className="text-red-500">*</span>
              )}
            </label>
            {step.name === "userPhotosRequestId" && (
              <button
                onClick={openPhotoSelectionModal}
                className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
              >
                Select or Upload Photos
              </button>
            )}
            {step.type === "text" && (
              <input
                type="text"
                name={step.name}
                value={settings[step.name]}
                onChange={handleInputChange}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-shadow duration-200"
              />
            )}
            {step.type === "number" && (
              <input
                type="number"
                name={step.name}
                value={settings[step.name]}
                onChange={handleInputChange}
                min={step.min}
                max={step.max}
                className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-shadow duration-200"
              />
            )}
            {step.type === "radio" && (
              <div className="flex flex-wrap gap-4">
                {step.options.map((option) => (
                  <label key={option} className="flex items-center">
                    <input
                      type="radio"
                      name={step.name}
                      value={option}
                      checked={settings[step.name] === option}
                      onChange={handleInputChange}
                      className="mr-2 text-blue-500 focus:ring-blue-500"
                    />
                    <span className="text-gray-700">{option}</span>
                  </label>
                ))}
              </div>
            )}
            {step.type === "text-dropdown" && (
              <div className="flex flex-col space-y-2">
                {step.options.includes(settings[step.name]) ? (
                  <select
                    name={step.name}
                    value={settings[step.name]}
                    onChange={(e) => handleDropdownChange(e, step)}
                    className="w-full p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-shadow duration-200"
                  >
                    {step.options.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                    <option value="custom">Custom</option>
                  </select>
                ) : (
                  <div className="flex items-center space-x-2">
                    <input
                      type="text"
                      name={step.name}
                      value={settings[step.name]}
                      onChange={(e) => handleTextDropdownInputChange(e, step)}
                      className="flex-grow p-3 border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-shadow duration-200"
                    />
                    <button
                      onClick={() =>
                        handleDropdownChange(
                          {
                            target: { name: step.name, value: step.options[0] },
                          },
                          step
                        )
                      }
                      className="p-2 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors duration-200"
                    >
                      ↺
                    </button>
                  </div>
                )}
              </div>
            )}
            {step.type === "checkbox" && (
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name={step.name}
                  checked={settings[step.name]}
                  onChange={handleInputChange}
                  className="mr-2 text-blue-500 focus:ring-blue-500"
                />
                <span className="text-gray-700">{step.label}</span>
              </label>
            )}
          </div>
        ))}
      </div>
      {showImageWarning && (
        <p className="text-red-500 mb-4">
          Please select an image before generating the story.
        </p>
      )}
      <div className="flex justify-between mt-6">
        <button
          onClick={handlePreviousStep}
          className="p-3 bg-gray-200 text-gray-700 rounded-lg hover:bg-gray-300 transition-colors duration-200"
          disabled={currentStep === 0}
        >
          Previous
        </button>
        {currentStep < sections.length - 1 ? (
          <button
            onClick={handleNextStep}
            className="p-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
          >
            Next
          </button>
        ) : (
          <button
            onClick={handleGenerate}
            className={`p-3 rounded-lg transition-colors duration-200 bg-green-400 hover:bg-green-600 text-white`}
          >
            Generate
          </button>
        )}
      </div>
    </div>
  );

  if (renderAsModal) {
    return (
      <>
        {showModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 overflow-y-auto">
            <div className="relative max-h-full w-full max-w-2xl">
              <button
                onClick={() => {
                  setShowModal(false);
                  onClose();
                }}
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 transition-colors duration-200"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
              {renderContent()}
            </div>
          </div>
        )}
        <PhotoSelectionModal
          isOpen={photoSelectionModalOpen}
          onClose={closePhotoSelectionModal}
          onPhotoSelected={handlePhotoSelected}
        />
      </>
    );
  }

  return renderContent();
};

export default TemplateCard;
