import { STORY_ACTIONS } from "../constants/actionTypes";
import {
  addOrReplaceImage,
  toggleImageSelection,
  toggleCoverImageSelection,
  updatePagePrompt,
  updateCoverImagePrompt,
  updatePageTextBlocks,
  deleteTextBlock,
  handlePageUpdate,
  handleCoverImageUpdate,
  handleBackgroundGeneration,
  handlePageManagement,
  handleImageOperations,
} from "../utils/reducerUtils";

export const initialState = {
  pages: [],
  storyText: "",
  hasChanges: false,
  currentImageIndex: 0,
  currentPage: 0,
  bookId: null,
  authorId: null,
  email: null,
  coverImages: [],
  titleImages: [],
  imageLayout: "",
  title: "",
  userPhotosRequestId: null,
  settings: {},
  is_paid: false,
  generation_status: {
    total: 0,
    completed: 0,
    generating: 0,
    failed: 0,
    is_complete: false,
    progress: 0,
    statuses: [],
  },
  selectedImageIndex: {
    left: 0,
    right: 0,
  },
  backgroundGenerationStatus: {
    coverStatusId: null,
    titleStatusId: null,
    status: null,
    error: null,
  },
  backgroundGenerationResults: {
    coverResults: [],
    titleResults: [],
  },
};

const storyReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Story metadata actions
    case STORY_ACTIONS.SET_CURRENT_STORY_ID:
      return { ...state, id: payload };

    case STORY_ACTIONS.SET_STORY_TEXT:
    case STORY_ACTIONS.SET_TITLE:
      return { ...state, [payload.field]: payload.value, hasChanges: true };

    case STORY_ACTIONS.SET_USER_PHOTOS_REQUEST_ID:
      return { ...state, userPhotosRequestId: payload };

    case STORY_ACTIONS.SET_STORY_STATE:
      return handlePageUpdate.setStoryState(state, payload);

    case STORY_ACTIONS.RESET_STORY:
      return initialState;

    // Page and text block management
    case STORY_ACTIONS.UPDATE_PAGE_TEXT_BLOCKS:
      return {
        ...state,
        pages: updatePageTextBlocks(state.pages, payload),
        hasChanges: true,
      };

    case STORY_ACTIONS.DELETE_TEXT_BLOCK:
      return {
        ...state,
        pages: deleteTextBlock(state.pages, payload),
        hasChanges: true,
      };

    case STORY_ACTIONS.ADD_NEW_TEXT_BLOCK:
      return handlePageManagement.addNewTextBlock(state, payload);

    // Image operations
    case STORY_ACTIONS.ADD_OR_REPLACE_IMAGE:
      return addOrReplaceImage(state, payload);

    case STORY_ACTIONS.TOGGLE_IMAGE_SELECTION:
      return handleImageOperations.toggleSelection(state, payload);

    case STORY_ACTIONS.UPDATE_IMAGE_PROMPT:
      return handleImageOperations.updatePrompt(state, payload);

    case STORY_ACTIONS.SET_COVER:
      return handleCoverImageUpdate.setCover(state, payload);

    case STORY_ACTIONS.SET_DEFAULT_IMAGE:
      return handleImageOperations.setDefaultImage(state, payload);

    // Page management
    case STORY_ACTIONS.ADD_PAGE:
    case STORY_ACTIONS.DELETE_PAGE:
    case STORY_ACTIONS.REORDER_PAGES:
      return handlePageManagement.handlePageAction(state, { type, payload });

    // Background generation
    // case STORY_ACTIONS.SET_BACKGROUND_GENERATION_STATUS:
    // case STORY_ACTIONS.SET_BACKGROUND_GENERATION_RESULTS:
    //   return handleBackgroundGeneration(state, { type, payload });

    // Face and positioning
    case STORY_ACTIONS.UPDATE_FACE_POSITION:
    case STORY_ACTIONS.UPDATE_ANGLE_RATIO:
    case STORY_ACTIONS.UPDATE_CONTROLNET_STRENGTH:
    case STORY_ACTIONS.UPDATE_FACE_SIZE:
    case STORY_ACTIONS.TOGGLE_NEW_SEED:
    case STORY_ACTIONS.UPDATE_USER_PROMPT:
      return handlePageUpdate.updatePageAttribute(state, { type, payload });

    case STORY_ACTIONS.UPDATE_PAGE_NUMBER:
      return {
        ...state,
        currentPage: payload.pageNumber,
        hasChanges: true,
      };

    case STORY_ACTIONS.REMOVE_IMAGE:
      return handleImageOperations.removeImage(state, payload);

    default:
      return state;
  }
};

export default storyReducer;
