import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "context/AuthContext";
import { PhotoIcon, XMarkIcon, StarIcon } from "@heroicons/react/24/outline";
import Cropper from "react-easy-crop";

const MAX_FILE_SIZE = 5 * 1024 * 1024;
const MAX_PHOTOS = 3;

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

const getCroppedImg = async (imageSrc, pixelCrop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  canvas.width = safeArea;
  canvas.height = safeArea;

  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );

  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  ctx.putImageData(
    data,
    0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
    0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
  );

  return new Promise((resolve) => {
    canvas.toBlob((blob) => {
      resolve(URL.createObjectURL(blob));
    }, "image/png");
  });
};

const UploadPhotoModal = ({ onClose, onSubmit }) => {
  const { user } = useAuth();
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [hairReferenceIndex, setHairReferenceIndex] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const frontalInputRef = useRef(null);

  const handlePhotoChange = (e) => {
    const files = Array.from(e.target.files).slice(
      0,
      MAX_PHOTOS - uploadedPhotos.length
    );
    const validFiles = files.filter((file) => {
      if (file.size > MAX_FILE_SIZE) {
        alert(`${file.name} exceeds the 5 MB limit and was not added.`);
        return false;
      }
      return true;
    });

    if (uploadedPhotos.length + validFiles.length > MAX_PHOTOS) {
      alert(`You can upload a maximum of ${MAX_PHOTOS} photos.`);
      return;
    }

    validFiles.forEach((file) => {
      const localUrl = URL.createObjectURL(file);
      setSelectedPhoto({ file, url: localUrl });
    });
  };

  const handleCrop = async () => {
    if (!selectedPhoto) return;
    try {
      const croppedImageUrl = await getCroppedImg(
        selectedPhoto.url,
        croppedAreaPixels
      );
      const response = await fetch(croppedImageUrl);
      const blob = await response.blob();
      const file = new File([blob], selectedPhoto.file.name, {
        type: "image/png",
      });
      const newUploadedPhotos = [...uploadedPhotos, file];
      
      // If this is the first photo, automatically set it as hair reference
      let newHairReferenceIndex = hairReferenceIndex;
      if (hairReferenceIndex === null) {
        newHairReferenceIndex = newUploadedPhotos.length - 1;
        setHairReferenceIndex(newHairReferenceIndex);
      }

      // Reorder photos array so hair reference is first
      const reorderedPhotos = [...newUploadedPhotos];
      if (newHairReferenceIndex !== 0) {
        const hairRef = reorderedPhotos[newHairReferenceIndex];
        reorderedPhotos.splice(newHairReferenceIndex, 1);
        reorderedPhotos.unshift(hairRef);
        newHairReferenceIndex = 0;
      }
      
      setUploadedPhotos(reorderedPhotos);
      setHairReferenceIndex(0);
    } catch (error) {
      console.error("Error cropping image:", error);
    }
    setSelectedPhoto(null);
  };

  const onCropCompleteHandler = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleRemovePhoto = (indexToRemove) => {
    const newUploadedPhotos = uploadedPhotos.filter(
      (_, index) => index !== indexToRemove
    );
    
    // If removing hair reference photo (index 0), set new first photo as reference
    if (indexToRemove === 0) {
      setHairReferenceIndex(newUploadedPhotos.length > 0 ? 0 : null);
    }
    
    setUploadedPhotos(newUploadedPhotos);
  };

  const setAsHairReference = (index) => {
    if (index === 0) return; // Already hair reference
    
    const reorderedPhotos = [...uploadedPhotos];
    const hairRef = reorderedPhotos[index];
    reorderedPhotos.splice(index, 1);
    reorderedPhotos.unshift(hairRef);
    
    setUploadedPhotos(reorderedPhotos);
    setHairReferenceIndex(0);
  };

  useEffect(() => {
    // Add event listener to file input
    if (frontalInputRef.current) {
      frontalInputRef.current.addEventListener('change', handlePhotoChange);
    }
    return () => {
      if (frontalInputRef.current) {
        frontalInputRef.current.removeEventListener('change', handlePhotoChange);
      }
    };
  }, [uploadedPhotos]);

  const handleSubmitPhotos = () => {
    if (uploadedPhotos.length === 0) {
      alert("Please upload at least one photo.");
      return;
    }
    onSubmit(uploadedPhotos); // Pass the uploaded photos back to parent
    onClose();
  };

  return (
    <div className="space-y-6">
      <div className="mb-4 text-sm text-gray-600">
        <p>
          <strong>Photo Upload Tips:</strong>
        </p>
        <ul className="list-disc list-inside">
          <li>No glasses or hats</li>
          <li>Clear, high-quality image of the face</li>
          <li>Forward-facing photos</li>
          <li>1-3 frontal photos only</li>
          <li>The first photo (marked with star) will be used as hair reference</li>
        </ul>
      </div>
      <div>
        <label className="block mb-2 font-semibold text-gray-700">
          Upload Frontal Photos (1-3)
        </label>
        <div className="flex space-x-4">
          {uploadedPhotos.map((photo, index) => (
            <div key={index} className="relative">
              <img
                src={URL.createObjectURL(photo)}
                alt={`Uploaded ${index + 1}`}
                className="w-24 h-24 object-cover rounded-lg"
              />
              <button
                onClick={() => handleRemovePhoto(index)}
                className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition"
              >
                <XMarkIcon className="h-4 w-4" />
              </button>
              <button
                onClick={() => setAsHairReference(index)}
                className={`absolute bottom-0 right-0 p-1 rounded-full transition ${
                  index === 0
                    ? "bg-yellow-500 text-white"
                    : "bg-gray-200 text-gray-600 hover:bg-yellow-200"
                }`}
                title={index === 0 ? "Hair reference photo" : "Set as hair reference"}
              >
                <StarIcon className="h-4 w-4" />
              </button>
            </div>
          ))}
          {uploadedPhotos.length < MAX_PHOTOS && (
            <div className="relative border-2 border-dashed border-gray-300 rounded-lg p-4 hover:border-blue-500 transition-colors duration-300">
              <input
                type="file"
                accept="image/*"
                ref={frontalInputRef}
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
              />
              <div className="text-center">
                <PhotoIcon className="mx-auto h-6 w-6 text-gray-400" />
                <p className="mt-1 text-sm text-gray-600">Click to upload</p>
              </div>
            </div>
          )}
        </div>
        <div className="mt-4 flex justify-end space-x-4">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 transition duration-300"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmitPhotos}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-300"
          >
            Submit
          </button>
        </div>
      </div>
      {selectedPhoto && (
        <CropModal
          selectedPhoto={selectedPhoto}
          crop={crop}
          zoom={zoom}
          onCropComplete={onCropCompleteHandler}
          setCrop={setCrop}
          setZoom={setZoom}
          handleCrop={handleCrop}
        />
      )}
    </div>
  );
};

const CropModal = ({
  selectedPhoto,
  crop,
  zoom,
  onCropComplete,
  setCrop,
  setZoom,
  handleCrop,
}) => (
  <div className="fixed inset-0 bg-black bg-opacity-75 z-50 flex flex-col">
    <div className="bg-gray-800 text-white p-4 flex justify-between items-center">
      <h2 className="text-xl font-semibold">Crop Image</h2>
    </div>
    <div className="flex-grow relative">
      <Cropper
        image={selectedPhoto.url}
        crop={crop}
        zoom={zoom}
        aspect={1}
        onCropChange={setCrop}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
      />
    </div>
    <div className="bg-gray-800 p-4 flex justify-end space-x-4">
      <button
        onClick={() => handleCrop()}
        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300"
      >
        Crop
      </button>
    </div>
  </div>
);

export default UploadPhotoModal;