import React from "react";
import { useParams } from "react-router-dom";
import PersonalizationForm from "./PersonalizationForm";
import { getBookData } from "../utils/bookData";
import ProductDisplay from "./ProductDisplay";
import AdditionalDetails from "./AdditionalDetails";

const PersonalizePage = () => {
  const { bookId } = useParams();
  console.log(bookId);
  const bookData = getBookData(bookId);
  console.log(bookData);
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8 grid grid-cols-1 md:grid-cols-2 gap-8">
        <ProductDisplay bookData={bookData} />
        <PersonalizationForm addOns={bookData.personalizationDetails.addOns} />
      </div>
      <AdditionalDetails bookData={bookData} />
    </div>
  );
};

export default PersonalizePage;
