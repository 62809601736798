import React from 'react';
import { CheckIcon, StarIcon } from '@heroicons/react/24/outline';

function AdditionalDetails({ bookData }) {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="border-b pb-6 mb-6">
          <h2 className="text-2xl font-bold mb-4">What's Included</h2>
          <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <li className="flex items-center gap-2">
              <CheckIcon className="w-5 h-5 text-green-500" />
              <span>Personalized story featuring your child</span>
            </li>
            <li className="flex items-center gap-2">
              <CheckIcon className="w-5 h-5 text-green-500" />
              <span>High-quality hardcover printing</span>
            </li>
            <li className="flex items-center gap-2">
              <CheckIcon className="w-5 h-5 text-green-500" />
              <span>Custom illustrations</span>
            </li>
            <li className="flex items-center gap-2">
              <CheckIcon className="w-5 h-5 text-green-500" />
              <span>Preview before printing</span>
            </li>
          </ul>
        </div>

        <div>
          <h2 className="text-2xl font-bold mb-4">Customer Reviews</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {bookData.reviews.map((review, idx) => (
              <div key={idx} className="border rounded-lg p-4">
                <div className="flex gap-1 mb-2">
                  {[...Array(review.rating)].map((_, i) => (
                    <StarIcon key={i} className="w-4 h-4 text-yellow-400" />
                  ))}
                </div>
                <p className="text-gray-600 mb-2 italic">"{review.quote}"</p>
                <p className="text-sm font-semibold">{review.author}</p>
                <p className="text-sm text-gray-500">{review.role}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdditionalDetails; 