import { useAuth } from "../context/AuthContext";
import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`;

const useImageService = () => {
  const { user, refreshToken } = useAuth();

  const authFetch = async (url, options = {}) => {
    const headers = {
      ...options.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${user?.accessToken}`,
    };

    try {
      const response = await fetch(url, { ...options, headers });
      if (response.status === 401) {
        const newToken = await refreshToken();
        if (newToken) {
          headers.Authorization = `Bearer ${newToken}`;
          return fetch(url, { ...options, headers });
        }
      }
      return response;
    } catch (error) {
      console.error("Error in authFetch:", error);
      throw error;
    }
  };

  const setDefaultImage = async (defaultImageData) => {
    console.log("BACKEND set default");
    try {
      const response = await authFetch(`${base_backend}/image/set-default`, {
        method: "POST",
        body: JSON.stringify(defaultImageData),
      });

      if (response.ok) {
        console.log(response);
        return response;
      } else {
        console.error("An error occurred while setting default the images");
        throw new Error("Failed to set default image");
      }
    } catch (error) {
      console.error(
        "An error occurred while setting default the images:",
        error
      );
      throw error;
    }
  };

  const setCover = async (imageId, bookId, isFrontCover) => {
    try {
      const response = await authFetch(`${base_backend}/image/set-cover`, {
        method: "POST",
        body: JSON.stringify({ imageId, bookId, isFrontCover }),
      });

      if (response.ok) {
        console.log(
          `${isFrontCover ? "Front" : "Back"} cover set successfully`
        );
        return response;
      } else {
        console.error(
          `An error occurred while setting the ${
            isFrontCover ? "front" : "back"
          } cover`
        );
        throw new Error(
          `Failed to set ${isFrontCover ? "front" : "back"} cover`
        );
      }
    } catch (error) {
      console.error(
        `An error occurred while setting the ${
          isFrontCover ? "front" : "back"
        } cover:`,
        error
      );
      throw error;
    }
  };

  const imageGenSingle = async (
    imagePrompts,
    updateImageCallback,
    setIsGeneratingImages
  ) => {
    try {
      setIsGeneratingImages(true);
      console.log("Initiating batch generation");
      const response = await authFetch(`${base_backend}/image/imagegensingle`, {
        method: "POST",
        body: JSON.stringify(imagePrompts),
      });
      console.log("THE RESPONSE: ", response);
      if (response.ok) {
        const data = await response.json();
        console.log("THE RESPONSE: ", data);
        // const result = data.images[0];
        // updateImageCallback(result.imageUrl, result.pageNumber, result.imagePrompt, result.id);
      }
    } catch (error) {
      console.error("An error occurred while generating the images:", error);
    } finally {
      setIsGeneratingImages(false);
    }
  };
  const batchGenerateImages = async (
    imagePrompts,
    userPhotosRequestId,
    layout,
    updateImageCallback,
    setIsGeneratingImages,
    bookId
  ) => {
    try {
      setIsGeneratingImages(true);
      console.log("Initiating batch generation");
      const initiationResults = await initiateBatchGeneration(
        imagePrompts,
        userPhotosRequestId,
        layout
      );
      console.log("THE INITIATION RESULTS: ", initiationResults);

      const pollingPromises = initiationResults
        .map((initiation) =>
          initiation.imagePrompts.map((imageData) =>
            pollForBatchImageResult(
              imageData,
              initiation.id,
              layout,
              updateImageCallback,
              initiation.splitImageIds,
              bookId
            )
          )
        )
        .flat();

      await Promise.all(pollingPromises);
    } catch (error) {
      console.error("An error occurred while generating the images:", error);
    } finally {
      setIsGeneratingImages(false);
    }
  };

  const initiateBatchGeneration = async (
    imagePrompts,
    userPhotosRequestId,
    layout
  ) => {
    try {
      const data = {
        userId: user.id,
        userPhotosRequestId,
        layout,
        imagePrompts,
      };

      console.log("Initiating Batch Generation: ", data);

      const response = await authFetch(`${base_backend}/image/batch`, {
        method: "POST",
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      console.log("THE RESPONSE: ", responseData);
      const results = responseData.results;
      console.log("Results: ", results);

      return results.map(({ id, imagePrompt, pageNumber, statusId }) => ({
        id: statusId,
        status: "pending",
        imagePrompts: [{ imagePrompt, pageNumber, id }],
        splitImageIds: id,
      }));
    } catch (error) {
      console.error("Error initiating image generation:", error);
      throw error;
    }
  };

  const pollForBatchImageResult = async (
    imageData,
    genId,
    layout,
    updateImageCallback,
    splitImageIds,
    bookId
  ) => {
    const maxAttempts = 50;
    let attempts = 0;

    while (attempts < maxAttempts) {
      attempts++;
      await new Promise((resolve) => setTimeout(resolve, 5000));

      try {
        const response = await authFetch(
          `${base_backend}/image/poll/${bookId}/${user.id}/${genId}?split_image_ids=${splitImageIds}`
        );

        if (response.ok) {
          const data = await response.json();
          if (data.status === "COMPLETED") {
            let result;
            if (layout === "double") {
              result = data.images;
            } else {
              result = data.images.find((image) => image.id === imageData.id);
            }

            if (Array.isArray(result)) {
              const urls = result.map((image) => image.imageUrl);
              const ids = result.map((image) => image.id);
              const imagePrompts = result.map((image) => image.imagePrompt);
              const actual_image_urls = [];
              updateImageCallback(
                urls,
                imageData.pageNumber,
                imagePrompts,
                ids,
                actual_image_urls
              );
            } else {
              updateImageCallback(
                result.imageUrl,
                imageData.pageNumber,
                result.imagePrompt,
                result.id
              );
            }
            return;
          }
        }
      } catch (error) {
        console.error("Error polling for image result:", error);
      }
    }

    throw new Error("Polling max attempts reached without completion");
  };

  const pollForCoverImageResults = async (
    statusId,
    bookId,
    updateCoverImageCallback,
    splitImageIds
  ) => {
    console.log(
      "POLLING FOR COVER IMAGE RESULTS",
      statusId,
      bookId,
      splitImageIds
    );
    const maxAttempts = 50;
    let attempts = 0;

    while (attempts < maxAttempts) {
      attempts++;
      await new Promise((resolve) => setTimeout(resolve, 5000));

      try {
        const response = await authFetch(
          `${base_backend}/image/poll/${bookId}/${user.id}/${statusId}?type=cover&split_image_ids=${splitImageIds}`
        );

        if (response.ok) {
          const data = await response.json();
          if (data.status === "COMPLETED") {
            const coverImages = data.images.map((image) => ({
              imageId: image.id,
              imagePrompt: image.imagePrompt,
              is_default: false,
              layout_info: {
                position: { x: "10%", y: "20%", width: "80%", height: "60%" },
                zIndex: 1,
                fullPageOverlay: false,
              },
              imageUrl: image.imageUrl,
              pageNumber: 0,
            }));

            updateCoverImageCallback(coverImages);
            return;
          }
        }
      } catch (error) {
        console.error("Error polling for cover image results:", error);
      }
    }

    throw new Error("Polling max attempts reached without completion");
  };

  const generateCoverImages = async (
    bookId,
    title,
    prompt,
    userPhotosRequestId,
    imageId = null,
    face_data
  ) => {
    try {
      const response = await authFetch(`${base_backend}/image/cover/generate`, {
        method: "POST",
        body: JSON.stringify({
          bookId,
          title,
          prompt,
          userId: user.id,
          userPhotosRequestId,
          imageId,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return data;
      }
      console.log(bookId, title, prompt, user.id, userPhotosRequestId, imageId);
      throw new Error("Failed to initiate cover image generation");
    } catch (error) {
      console.error("Error generating cover images:", error);
      throw error;
    }
  };

  const generateTitleImages = async (bookId, title) => {
    try {
      const response = await authFetch(`${base_backend}/image/title/generate`, {
        method: "POST",
        body: JSON.stringify({ bookId, title, userId: user.id }),
      });

      if (response.ok) {
        const data = await response.json();
        return data.status_id;
      }

      throw new Error("Failed to initiate title image generation");
    } catch (error) {
      console.error("Error generating title images:", error);
      throw error;
    }
  };

  const pollForTitleImageResults = async (
    statusId,
    bookId,
    updateTitleImageCallback
  ) => {
    const maxAttempts = 50;
    let attempts = 0;

    while (attempts < maxAttempts) {
      attempts++;
      await new Promise((resolve) => setTimeout(resolve, 5000));

      try {
        const response = await authFetch(
          `${base_backend}/image/title/${bookId}/${user.id}/${statusId}`
        );

        if (response.ok) {
          const data = await response.json();
          if (data.status === "COMPLETED") {
            const titleImages = data.images.map((image) => ({
              imageId: image.id,
              imagePrompt: image.imagePrompt,
              is_default: false,
              layout_info: {
                position: { x: "10%", y: "20%", width: "80%", height: "60%" },
                zIndex: 1,
                fullPageOverlay: false,
              },
              imageUrl: image.imageUrl,
              pageNumber: 0,
            }));

            updateTitleImageCallback(titleImages);
            return;
          }
        }
      } catch (error) {
        console.error("Error polling for title image results:", error);
      }
    }

    throw new Error("Polling max attempts reached without completion");
  };

  const insertTitleIntoCover = async (bookId, coverId, titleId) => {
    try {
      const response = await authFetch(
        `${base_backend}/image/insert_title_into_cover`,
        {
          method: "POST",
          body: JSON.stringify({ bookId, coverId, titleId, userId: user.id }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        return data.status_id;
      }

      throw new Error("Failed to initiate title insertion into cover");
    } catch (error) {
      console.error("Error inserting title into cover:", error);
      throw error;
    }
  };

  const pollForMergedImageResults = async (
    statusId,
    bookId,
    updateMergedImageCallback
  ) => {
    const maxAttempts = 50;
    let attempts = 0;

    while (attempts < maxAttempts) {
      attempts++;
      await new Promise((resolve) => setTimeout(resolve, 5000));

      try {
        const response = await authFetch(
          `${base_backend}/image/poll/${bookId}/${user.id}/${statusId}?type=cover`
        );
        console.log("THE RESPONSE: ", response);

        if (response.ok) {
          const data = await response.json();
          console.log("THE DATA: ", data);
          if (data.status === "COMPLETED") {
            console.log("IN HERE SETTING RESULTS");
            const mergedImage = data.images.map((image) => ({
              imageId: image.id,
              imagePrompt: image.imagePrompt,
              is_default: true,
              layout_info: {
                position: { x: "10%", y: "20%", width: "80%", height: "60%" },
                zIndex: 1,
                fullPageOverlay: false,
              },
              imageUrl: image.imageUrl,
              pageNumber: 0,
            }))[0];

            console.log("THE MERGED IMAGE SERVICE: ", mergedImage);
            updateMergedImageCallback(mergedImage);
            return;
          }
        }
      } catch (error) {
        console.error("Error polling for merged image results:", error);
      }
    }

    throw new Error("Polling max attempts reached without completion");
  };

  return {
    imageGenSingle,
    setDefaultImage,
    batchGenerateImages,
    generateCoverImages,
    pollForCoverImageResults,
    generateTitleImages,
    pollForTitleImageResults,
    insertTitleIntoCover,
    pollForMergedImageResults,
    setCover,
  };
};

export default useImageService;
