import React from "react";

const ActionButtons = ({ actions }) => {
  return actions.map((action, index) => (
    <button
      key={index}
      onClick={action.onClick}
      className={`
        w-full px-4 py-2 mb-2 last:mb-0 flex items-center justify-center
        text-white rounded-md transition-all duration-200
        focus:outline-none focus:ring-2 focus:ring-offset-2
        ${
          action.disabled || action.loading
            ? "bg-gray-400 cursor-not-allowed"
            : `${action.className} hover:opacity-90 focus:ring-opacity-50`
        }
      `}
      disabled={action.disabled || action.loading}
    >
      {action.loading ? (
        <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
      ) : (
        action.icon && <action.icon className="w-5 h-5 mr-2" />
      )}
      {action.loading ? "Processing..." : action.label}
    </button>
  ));
};

export default ActionButtons;
