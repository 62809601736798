import React, { useState, useCallback } from "react";
import TextBlockManager from "../hooks/textBlockManager";
import ImageArea from "./ImageArea";

const Page = ({
  isEditable,
  page,
  isGeneratingImages,
  selectedImageIndex,
  onImageClick,
}) => {
  const [imageBounds, setImageBounds] = useState(null);

  const handleImageBoundsChange = useCallback((bounds) => {
    setImageBounds(bounds);
  }, []);

  const pageImages = page.images || [];
  const sortedCurrentImages = [
    ...pageImages?.filter((image) => image.is_default),
    ...pageImages?.filter((image) => !image.is_default),
  ];
  return (
    <div className="relative rounded shadow grow flex justify-center items-center">
      <div className="flex flex-col items-center relative w-full h-full">
        {imageBounds && (
          <TextBlockManager
            pageId={page.pageId}
            isEditable={isEditable}
            imageBounds={imageBounds}
          />
        )}
        <ImageArea
          isEditable={isEditable}
          passedImages={sortedCurrentImages}
          pageNumber={page.pageNumber}
          isGeneratingImages={isGeneratingImages}
          selectedImageIndex={selectedImageIndex}
          onImageBoundsChange={handleImageBoundsChange}
          onImageClick={onImageClick}
        />
      </div>
    </div>
  );
};

export default Page;
