import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const PurchaseSuccessPage = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState("loading");

  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    const returnPath = localStorage.getItem("purchaseReturnPath");

    if (sessionId) {
      setStatus("success");
      // Redirect after a delay
      setTimeout(() => {
        if (returnPath) {
          localStorage.removeItem("purchaseReturnPath");
          navigate(returnPath);
        } else {
          navigate("/");
        }
      }, 5000);
    }
  }, [searchParams, navigate]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow">
        <div className="text-center">
          <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
            Thank you for your purchase!
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Your book is now being generated. We'll email you when it's ready.
          </p>
          <p className="mt-2 text-sm text-gray-600">
            Redirecting you back in a few seconds...
          </p>
        </div>
      </div>
    </div>
  );
};

export default PurchaseSuccessPage;
