import { useBookPage } from "./useBookPage";
import { useBookImages } from "./useBookImages";
import { useBookSelection } from "./useBookSelection";

export const useBook = () => {
  const bookPageState = useBookPage();
  const bookImageState = useBookImages();
  const bookSelectionState = useBookSelection();

  const {
    storyState,
    viewMode,
    isGenerating,
    handlePageChange,
    getCurrentPagePrompt,
    handlePromptChange,
    handleFaceSizeChange,
    getCurrentPageFaceSize,
  } = bookPageState;
  console.log("STORY STATE In useBook", storyState);
  const {
    isColorCorrecting,
    isGeneratingPages,
    clickedCoords,
    handleColorCorrect,
    handleGenerateImages,
    handleDownloadAll,
    handleGeneratePages,
    handleAdjustImage,
    handleImageClick,
    sortedRightImages,
    isAdjusting,
  } = bookImageState;

  const {
    selectedImages,
    generateMode,
    selectedImageIndex,
    handleImageToggle,
    handleSetDefaultImage,
    toggleGenerateMode,
    handleImageNavigation,
    handleDeleteImage,
  } = bookSelectionState;

  // Combine states for sidebar actions
  const sidebarState = {
    storyState,
    generateMode,
    selectedImages,
    isGeneratingPages,
    isGenerating,
    isColorCorrecting,
    handleGeneratePages,
    handleGenerateImages,
    handleColorCorrect,
    handleDownloadAll,
  };

  // Combine states for book display
  const bookDisplayState = {
    story: storyState,
    viewMode,
    isGenerating: isGenerating,
    selectedImageIndex,
    currentPage: storyState.currentPage,
  };

  // Combine states for generation sidebar
  const generationState = {
    generateMode,
    clickedCoords,
    getCurrentPagePrompt,
    handlePromptChange,
    getCurrentPageFaceSize,
    handleFaceSizeChange,
    toggleGenerateMode,
    sortedRightImages,
    selectedImageIndex,
    handleImageNavigation,
  };

  // Combine states for controls sidebar
  const controlsState = {
    selectedImageIndex,
    handleSetDefaultImage,
    handleAdjustImage,
    handleDeleteImage,
    handleImageToggle,
    sortedRightImages,
    storyState,
    handleImageNavigation,
    isAdjusting,
  };

  return {
    // States
    sidebarState,
    bookDisplayState,
    generationState,
    controlsState,
    storyState,

    // Event handlers
    handlePageChange,
    handleImageClick,
    toggleGenerateMode,
  };
};
