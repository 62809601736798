const ToggleButton = ({ label, options, value, onChange }) => (
  <div className="space-y-2">
    <span className="text-sm font-medium block">{label}</span>
    <div className="flex items-center justify-between bg-gray-200 rounded-full p-1">
      {options.map((option) => (
        <button
          key={option.value}
          className={`py-1 px-3 rounded-full text-sm flex items-center ${
            value === option.value ? "bg-white shadow" : ""
          }`}
          onClick={() => onChange(option.value)}
        >
          {option.icon && <option.icon className="w-4 h-4 mr-1" />}
          {option.label}
        </button>
      ))}
    </div>
  </div>
);

const Section = ({ title, children }) => (
  <div className="mb-6">
    <h3 className="text-lg font-semibold mb-3 text-gray-700">{title}</h3>
    {children}
  </div>
);
const Button = ({
  onClick,
  active,
  disabled,
  loading,
  icon: Icon,
  children,
  fullWidth,
}) => (
  <button
    onClick={onClick}
    disabled={disabled || loading}
    className={`
        ${fullWidth ? "w-full" : ""}
        ${active ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-800"}
        ${
          disabled || loading
            ? "opacity-50 cursor-not-allowed"
            : "hover:bg-blue-600 hover:text-white"
        }
        font-semibold py-2 px-4 rounded transition duration-200 ease-in-out
        flex items-center justify-center
      `}
  >
    {loading ? (
      <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        />
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        />
      </svg>
    ) : (
      Icon && <Icon className="w-5 h-5 mr-2" />
    )}
    {loading ? "Processing..." : children}
  </button>
);

const IconButton = ({ onClick, icon: Icon }) => (
  <button
    onClick={onClick}
    className="p-2 rounded-full bg-gray-200 text-gray-800 hover:bg-blue-500 hover:text-white transition duration-200 ease-in-out"
  >
    <Icon className="w-6 h-6" />
  </button>
);

const Checkbox = ({ checked, onChange, label }) => (
  <label className="flex items-center space-x-2 cursor-pointer">
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
      className="form-checkbox h-5 w-5 text-blue-500 rounded focus:ring-blue-500"
    />
    <span className="text-gray-700">{label}</span>
  </label>
);

const Slider = ({ min, max, step, value, onChange, marks }) => {
  // Create ordered array of mark values
  const orderedMarks = Object.entries(marks)
    .map(([value]) => Number(value))
    .sort((a, b) => a - b);

  return (
    <div className="space-y-2">
      <div className="flex justify-between items-center mb-1">
        <span className="text-sm text-gray-600">
          Current: {value.toFixed(2)}
        </span>
      </div>
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={(e) => onChange(Number(e.target.value))}
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer 
          dark:bg-gray-700
          [&::-webkit-slider-thumb]:appearance-none
          [&::-webkit-slider-thumb]:h-4
          [&::-webkit-slider-thumb]:w-4
          [&::-webkit-slider-thumb]:rounded-full
          [&::-webkit-slider-thumb]:bg-blue-500
          [&::-webkit-slider-thumb]:cursor-pointer
          [&::-moz-range-thumb]:h-4
          [&::-moz-range-thumb]:w-4
          [&::-moz-range-thumb]:rounded-full
          [&::-moz-range-thumb]:bg-blue-500
          [&::-moz-range-thumb]:border-0"
      />
      <div className="flex justify-between text-sm text-gray-600">
        {orderedMarks.map((value) => (
          <span key={value}>{value.toFixed(1)}</span>
        ))}
      </div>
    </div>
  );
};

export { ToggleButton, Section, Button, IconButton, Checkbox, Slider };
