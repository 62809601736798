import React, { useState, useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  XMarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

const BookPreviewModal = ({ isOpen, handleClose, book }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const bookRef = useRef(null);
  const navigate = useNavigate();

  if (!book) return null;

  const navigatePage = (newPage) => {
    if (newPage >= 0 && newPage <= book.pages.length) setCurrentPage(newPage);
  };

  const getCurrentPages = () => {
    if (currentPage === 0) return [{ type: "cover" }];
    if (currentPage >= book.pages.length) return [{ type: "end" }];
    const pageIndex = currentPage - 1;
    return [book.pages[pageIndex], book.pages[pageIndex + 1] || null];
  };

  const handleViewPDF = () => {
    const restructuredBook = {
      pages: [
        {
          images: [
            {
              image_url: book.coverImages.find((img) => img.is_default)
                ?.imageUrl,
              isPlaced: true,
            },
          ],
          text_blocks: [],
          width: 700,
          height: 700,
        },
        ...book.pages.map((page) => ({
          images: page.images
            .filter((img) => img.is_default)
            .map((img) => ({
              image_url: img.imageUrl,
              isPlaced: true,
            })),
          text_blocks: page.text_blocks.map((block) => ({
            id: block.id,
            content: block.content,
            block_data: {
              backgroundColor:
                block.backgroundColor +
                Math.round(block.opacity * 255)
                  .toString(16)
                  .padStart(2, "0"),
              fontFamily: block.fontFamily,
              fontSize: block.fontSize,
              textShadow: block.textShadow,
              fontWeight: block.fontWeight,
              fontStyle: block.fontStyle,
              textDecoration: block.textDecoration,
              width: block.width,
              height: block.height,
              textAlign: "left",
              relativeX: block.relativeX,
              relativeY: block.relativeY,
              x: block.x,
              y: block.y,
            },
            fontColor: block.color,
          })),
          width: 700,
          height: 700,
        })),
      ],
    };
    navigate("/book-overlay", { state: { book: restructuredBook } });
  };

  const currentPages = getCurrentPages();

  return (
    <div
      className={`fixed inset-0 z-50 ${
        isOpen ? "flex" : "hidden"
      } items-center justify-center bg-black bg-opacity-50`}
    >
      <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[95vh] flex flex-col">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-2xl font-bold text-gray-800">Book Preview</h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <XMarkIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="grow overflow-auto p-4" ref={bookRef}>
          <div className="rounded-lg shadow-inner p-4 flex justify-center items-center h-full">
            {currentPages[0].type === "cover" ? (
              <BookCoverPreview coverImages={book.coverImages} />
            ) : currentPages[0].type === "end" ? (
              <TheEndPreview />
            ) : (
              <div className="flex justify-between w-full space-x-4">
                <PagePreview page={currentPages[0]} />
                {currentPages[1] && <PagePreview page={currentPages[1]} />}
              </div>
            )}
          </div>
        </div>
        <div className="p-4 border-t">
          <NavigationPreview
            currentPageNum={currentPage}
            totalPages={book.pages.length + 1}
            onNavigate={navigatePage}
          />
          <div className="flex justify-end space-x-2 mt-4">
            <button
              onClick={handleViewPDF}
              className="px-4 py-2 bg-purple-400 text-white rounded-lg hover:bg-purple-600 transition-colors flex items-center"
            >
              <DocumentTextIcon className="h-5 w-5 mr-2" />
              View As PDF
            </button>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 transition-colors"
              onClick={handleClose}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const BookCoverPreview = ({ coverImages }) => {
  const defaultImage =
    coverImages.find((img) => img.is_front_cover && img.is_default) ||
    coverImages[0];
  return (
    <div className="bg-white rounded-lg shadow-lg flex flex-col items-center justify-center">
      <div className="grow flex items-center justify-center max-w-[60vh]">
        <ImagePreview image={defaultImage} imageBoundsChanged={() => {}} />
      </div>
    </div>
  );
};

const PagePreview = ({ page }) => {
  const containerRef = useRef(null);
  const [bounds, setBounds] = useState(null);

  const handleImageBoundsChanged = useCallback((bounds) => {
    setBounds(bounds);
  }, []);

  const defaultImage =
    page.images.find((image) => image.is_default) || page.images[0];
  return (
    <div
      ref={containerRef}
      className="relative bg-white rounded-lg shadow-lg w-1/2 h-full flex flex-col items-center justify-center"
    >
      <ImagePreview
        image={defaultImage}
        imageBoundsChanged={handleImageBoundsChanged}
      />
      <TextBlockDisplays textBlocks={page.text_blocks} bounds={bounds} />
    </div>
  );
};

const ImagePreview = ({ image, imageBoundsChanged }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    if (imageLoaded) {
      const imgRect = imgRef.current.getBoundingClientRect();
      imageBoundsChanged(imgRect);
    }
  }, [image, imageLoaded]);

  return (
    <div className="relative w-full h-full flex items-center justify-center">
      {image ? (
        <img
          ref={imgRef}
          src={image.imageUrl || ""}
          alt=""
          className="max-w-full max-h-full object-contain"
          onLoad={() => setImageLoaded(true)}
        />
      ) : (
        <div className="flex justify-center items-center w-full h-full text-gray-500">
          No images available
        </div>
      )}
    </div>
  );
};

const TextBlockDisplays = ({ textBlocks, bounds }) => {
  const fontSizeOptions = {
    small: 0.015,
    medium: 0.02,
    large: 0.025,
    "extra-large": 0.03,
  };

  const calculateFontSize = (sizeCategory, containerWidth) => {
    const baseSize = fontSizeOptions[sizeCategory];
    const minFontSize = 12;
    const maxFontSize = 48;
    const calculatedSize = baseSize * containerWidth;
    return Math.min(Math.max(calculatedSize, minFontSize), maxFontSize);
  };

  return (
    <>
      {textBlocks.map((textBlock) => {
        if (!bounds) return null;
        const containerWidth = bounds.width;
        const containerHeight = bounds.height;
        const x = textBlock.x * containerWidth;
        const y = textBlock.y * containerHeight;
        const width = textBlock.width * containerWidth;
        const height = textBlock.height * containerHeight;
        const fontSize = calculateFontSize(textBlock.fontSize, containerWidth);
        return (
          <div
            key={textBlock.id}
            className="absolute"
            style={{
              left: `${x}px`,
              top: `${y}px`,
              fontFamily: textBlock.fontFamily,
              fontSize: `${fontSize}px`,
              color: textBlock.color,
              textShadow: textBlock.textShadow,
              fontWeight: textBlock.fontWeight,
              fontStyle: textBlock.fontStyle,
              textDecoration: textBlock.textDecoration,
              backgroundColor:
                textBlock.backgroundColor +
                Math.round(textBlock.opacity * 255)
                  .toString(16)
                  .padStart(2, "0"),
              backdropFilter: textBlock.backdropFilter,
              width: `${width}px`,
              height: `${height}px`,
            }}
          >
            <p>{textBlock.content}</p>
          </div>
        );
      })}
    </>
  );
};

const NavigationPreview = ({ currentPageNum, totalPages, onNavigate }) => (
  <div className="flex justify-between items-center">
    <button
      onClick={() => onNavigate(currentPageNum - 1)}
      disabled={currentPageNum === 0}
      className="p-2 text-gray-500 hover:text-gray-700 disabled:text-gray-300"
    >
      <ChevronLeftIcon className="h-6 w-6" />
    </button>
    <span className="text-gray-700">
      Page {currentPageNum + 1} of {totalPages}
    </span>
    <button
      onClick={() => onNavigate(currentPageNum + 1)}
      disabled={currentPageNum === totalPages - 1}
      className="p-2 text-gray-500 hover:text-gray-700 disabled:text-gray-300"
    >
      <ChevronRightIcon className="h-6 w-6" />
    </button>
  </div>
);

const TheEndPreview = () => (
  <div className="text-center py-8 w-full h-full flex items-center justify-center">
    <h2 className="text-3xl font-bold text-gray-800">The End</h2>
  </div>
);

export default BookPreviewModal;

// const BookPreviewModal = ({ isOpen, handleClose, book }) => {
//   const [currentPage, setCurrentPage] = useState(0);
//   const bookRef = useRef(null);
//   const navigate = useNavigate();

//   if (!book) return null;

//   const navigatePage = (newPage) => {
//     if (newPage >= 0 && newPage <= book.pages.length) setCurrentPage(newPage);
//   };

//   const getCurrentPages = () => {
//     if (currentPage === 0) return [{ type: "cover" }];
//     if (currentPage >= book.pages.length) return [{ type: "end" }];
//     const pageIndex = currentPage - 1;
//     return [book.pages[pageIndex], book.pages[pageIndex + 1] || null];
//   };

//   const handleViewPDF = () => {
//     const restructuredBook = {
//       pages: [
//         {
//           images: [
//             {
//               image_url: book.coverImages.find(img => img.is_default)?.imageUrl,
//               isPlaced: true
//             }
//           ],
//           text_blocks: [],
//           width: 700,
//           height: 700
//         },
//         ...book.pages.map((page) => ({
//           images: page.images.filter(img => img.is_default).map(img => ({
//             image_url: img.imageUrl,
//             isPlaced: true
//           })),
//           text_blocks: page.text_blocks.map(block => ({
//             id: block.id,
//             content: block.content,
//             block_data: {
//               backgroundColor:
//               block.backgroundColor +
//               Math.round(block.opacity * 255)
//                 .toString(16)
//                 .padStart(2, "0"),
//               fontFamily: block.fontFamily,
//               fontSize: block.fontSize,
//               textShadow: block.textShadow,
//               fontWeight: block.fontWeight,
//               fontStyle: block.fontStyle,
//               textDecoration: block.textDecoration,
//               width: block.width,
//               height: block.height,
//               textAlign: 'left',
//               relativeX: block.relativeX,
//               relativeY: block.relativeY,
//               x: block.x,
//               y: block.y

//             },
//             fontColor: block.color
//           })),
//           width: 700,
//           height: 700
//         }))
//       ]
//     };
//     console.log("THE BOOK WE'RE USING", restructuredBook)
//     navigate('/book-overlay', { state: { book: restructuredBook } });
//   };

//   const currentPages = getCurrentPages();
//   return (
//     <div
//       className={`fixed inset-0 flex items-center justify-center z-50 ${
//         isOpen ? "block" : "hidden"
//       } bg-black bg-opacity-50`}
//     >
//       <div className="relative bg-white p-2 rounded-lg border border-black flex flex-col max-w-full max-h-full">
//         <div className="flex flex-col h-full w-full" ref={bookRef}>
//           <div className="grow">
//             <div className="grow border border-gray-200 rounded shadow flex justify-center max-w-full max-h-full">
//               {currentPages[0].type === "cover" ? (
//                 <BookCoverPreview
//                   coverImages={book.coverImages}
//                   title={book.title}
//                 />
//               ) : currentPages[0].type === "end" ? (
//                 <TheEndPreview />
//               ) : (
//                 <div className="flex justify-between w-full">
//                   <PagePreview page={currentPages[0]} />
//                   {currentPages[1] && <PagePreview page={currentPages[1]} />}
//                 </div>
//               )}
//             </div>
//             <NavigationPreview
//               currentPageNum={currentPage}
//               totalPages={book.pages.length + 1}
//               onNavigate={navigatePage}
//             />
//             <button
//               onClick={handleViewPDF}
//               className="p-1 bg-purple-400 text-white rounded-lg hover:bg-purple-600 transition-colors"
//             >
//               View As PDF
//             </button>
//             <button
//               className="p-1 bg-purple-400 text-white rounded-lg hover:bg-purple-600 transition-colors"
//               onClick={handleClose}
//             >
//               Close
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const BookCoverPreview = ({ coverImages, title }) => {
//   const defaultImage =
//     coverImages.find((image) => image.is_default) || coverImages[0];
//   return (
//     <div className="bg-gray-100 p-1 rounded shadow w-full h-full flex flex-col items-center justify-center">
//       <div className="grow flex items-center justify-center w-full">
//         <ImagePreview image={defaultImage} imageBoundsChanged={() => {}} />
//       </div>
//     </div>
//   );
// };

// const PagePreview = ({ page }) => {
//   const containerRef = useRef(null);
//   const [bounds, setBounds] = useState(null);

//   const handleImageBoundsChanged = (bounds) => {
//     setBounds(bounds);
//   };

//   const defaultImage =
//     page.images.find((image) => image.is_default) || page.images[0];
//   return (
//     <div
//       ref={containerRef}
//       className="relative bg-gray-100 rounded shadow w-1/2 h-full flex flex-col items-center justify-center"
//     >
//       <ImagePreview
//         image={defaultImage}
//         imageBoundsChanged={handleImageBoundsChanged}
//       />
//       <TextBlockDisplays textBlocks={page.text_blocks} bounds={bounds} />
//     </div>
//   );
// };

// const ImagePreview = ({ image, imageBoundsChanged }) => {
//   const [imageLoaded, setImageLoaded] = useState(false);
//   const imgRef = useRef(null);
//   useEffect(() => {
//     if (imageLoaded) {
//       const imgRect = imgRef.current.getBoundingClientRect();
//       imageBoundsChanged(imgRect);
//     }
//   }, [image, imageLoaded]);

//   return (
//     <div className="relative w-full h-full flex items-center justify-center">
//       {image ? (
//         <img
//           ref={imgRef}
//           src={image.imageUrl || ""}
//           alt=""
//           style={{ maxWidth: "80vw", maxHeight: "70vh" }}
//           className="w-full h-full object-contain"
//           onLoad={() => setImageLoaded(true)}
//         />
//       ) : (
//         <div className="flex justify-center items-center w-full h-full">
//           <div>No images available</div>
//         </div>
//       )}
//     </div>
//   );
// };

// const TextBlockDisplays = ({ textBlocks, bounds }) => {
//   const fontSizeOptions = {
//     small: 0.015,
//     medium: 0.02,
//     large: 0.025,
//     "extra-large": 0.03,
//   };

//   const calculateFontSize = (sizeCategory, containerWidth) => {
//     const baseSize = fontSizeOptions[sizeCategory];
//     const minFontSize = 12;
//     const maxFontSize = 48;
//     const calculatedSize = baseSize * containerWidth;
//     return Math.min(Math.max(calculatedSize, minFontSize), maxFontSize);
//   };

//   return (
//     <>
//       {textBlocks.map((textBlock) => {
//         if (!bounds) return null;
//         const containerWidth = bounds.width;
//         const containerHeight = bounds.height;
//         const x = textBlock.x * containerWidth;
//         const y = textBlock.y * containerHeight;
//         const width = textBlock.width * containerWidth;
//         const height = textBlock.height * containerHeight;
//         console.log("TEXT BLOCK", textBlock, containerWidth, containerHeight, x, y, width, height)
//         const fontSize = calculateFontSize(textBlock.fontSize, containerWidth);
//         return (
//           <div
//             key={textBlock.id}
//             className="absolute"
//             style={{
//               left: `${x}px`,
//               top: `${y}px`,
//               fontFamily: textBlock.fontFamily,
//               fontSize: `${fontSize}px`,
//               color: textBlock.color,
//               textShadow: textBlock.textShadow,
//               fontWeight: textBlock.fontWeight,
//               fontStyle: textBlock.fontStyle,
//               textDecoration: textBlock.textDecoration,
//               backgroundColor:
//                 textBlock.backgroundColor +
//                 Math.round(textBlock.opacity * 255)
//                   .toString(16)
//                   .padStart(2, "0"),
//               backdropFilter: textBlock.backdropFilter,
//               width: `${width}px`,
//               height: `${height}px`,
//             }}
//           >
//             <p>{textBlock.content}</p>
//           </div>
//         );
//       })}
//     </>
//   );
// };

// const NavigationPreview = ({ currentPageNum, totalPages, onNavigate }) => (
//   <div className="flex justify-between mt-4">
//     <button
//       onClick={() => onNavigate(currentPageNum - 1)}
//       disabled={currentPageNum === 0}
//       className="p-1 bg-gray-300 rounded"
//     >
//       Previous
//     </button>
//     <span>
//       Page {currentPageNum + 1} of {totalPages}
//     </span>
//     <button
//       onClick={() => onNavigate(currentPageNum + 1)}
//       disabled={currentPageNum === totalPages - 1}
//       className="p-1 bg-gray-300 rounded"
//     >
//       Next
//     </button>
//   </div>
// );

// const TheEndPreview = () => (
//   <div className="text-center py-8 w-full h-full flex items-center justify-center">
//     <h2 className="text-2xl">The End</h2>
//   </div>
// );

// export default BookPreviewModal;
