import React from "react";
import { ArrowUpTrayIcon } from "@heroicons/react/24/outline";
import UploadPhotoModal from "./UploadPhotoModal";
import { usePersonalizationForm } from "../hooks/usePersonalizationForm";

const PersonalizationForm = ({ addOns }) => {
  const {
    user,
    age,
    setAge,
    gender,
    setGender,
    email,
    setEmail,
    name,
    setName,
    hairDescription,
    setHairDescription,
    uploadedPhotos,
    isFirstStepComplete,
    setIsFirstStepComplete,
    showUploadModal,
    setShowUploadModal,
    personalizationMessage,
    setPersonalizationMessage,
    onPhotoUpload,
    onPersonalizationSubmit,
    isSubmitting,
    uploadData,
  } = usePersonalizationForm();

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">Personalize Your Book</h3>
      {!isFirstStepComplete ? (
        <div className="space-y-3">
          <input
            type="number"
            placeholder="Child's Age *"
            value={age}
            onChange={(e) => setAge(e.target.value)}
            className="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
            required
          />

          <select
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
            required
          >
            <option value="">Select Gender *</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>

          {!user && (
            <input
              type="email"
              placeholder="Email *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
              required
            />
          )}

          <div className="relative">
            <button
              onClick={() => setShowUploadModal(true)}
              className="w-full bg-gray-100 text-gray-700 font-semibold py-3 px-6 rounded-lg hover:bg-gray-200 transition-colors flex items-center justify-center gap-2"
            >
              <ArrowUpTrayIcon className="w-5 h-5" />
              Upload Photos * ({uploadedPhotos.length} selected)
            </button>
          </div>

          <button
            onClick={() => setIsFirstStepComplete(true)}
            className={`w-full bg-purple-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-purple-700 transition-colors flex items-center justify-center gap-2 ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSubmitting || !uploadData}
          >
            {isSubmitting ? "Processing..." : "Continue"}
          </button>
        </div>
      ) : (
        <div className="space-y-3">
          <input
            type="text"
            placeholder="Child's Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
          />

          <input
            type="text"
            placeholder="Hair Description (e.g., curly, straight)"
            value={hairDescription}
            onChange={(e) => setHairDescription(e.target.value)}
            className="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
          />

          <textarea
            placeholder="Personalization Message"
            value={personalizationMessage}
            onChange={(e) => setPersonalizationMessage(e.target.value)}
            className="w-full border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none"
            rows={3}
          />

          <button
            onClick={onPersonalizationSubmit}
            className="w-full bg-purple-600 text-white font-semibold py-3 px-6 rounded-lg hover:bg-purple-700 transition-colors"
          >
            Complete Personalization
          </button>
        </div>
      )}

      {showUploadModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-2xl w-full max-w-4xl">
            <UploadPhotoModal
              onClose={() => setShowUploadModal(false)}
              onSubmit={onPhotoUpload}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PersonalizationForm;
