import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`;
export const forgotPassword = async (email) => {
  try {
    const response = await fetch(`${base_backend}/auth/forgotpassword`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });
    if (response.ok) {
      const data = await response.json();
      // Handle response (e.g., showing a message to check their email)
      return data;
    } else {
      throw new Error("Forgot Password request failed");
    }
  } catch (error) {
    console.error("There was an error!", error);
  }
};

export const signupUser = async (data) => {
  try {
    const response = await fetch(`${base_backend}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    if (response.ok) {
      const data = await response.json();
      return {
        accessToken: data.access_token,
        refreshToken: data.refresh_token,
        userId: data.user_id,
        email: data.email,
        authenticated: true,
      };
    } else {
      const errorData = await response.json();
      throw new Error(errorData.error || "Signup failed");
    }
  } catch (error) {
    console.error("There was an error!", error);
    throw error;
  }
};

export const loginUser = async (data) => {
  try {
    const response = await fetch(`${base_backend}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Login failed");
    }

    const responseData = await response.json();
    console.log("Login response:", responseData);

    // Map the backend response to our frontend format
    return {
      accessToken: responseData.access_token,
      refreshToken: responseData.refresh_token,
      userId: responseData.user_id,
      email: responseData.email,
      authenticated: true,
    };
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};
