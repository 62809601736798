import React, { useState, useRef, useEffect } from "react";
import emptyImage from "../assets/emptyimage.png";
import "./ImageArea.css";

const ImageArea = ({
  pageNumber,
  isGeneratingImages,
  passedImages,
  onImageBoundsChange,
  selectedImageIndex,
  onImageClick
}) => {
  const imgRef = useRef(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    if (onImageBoundsChange && imageLoaded) {
      const imageBounds = calculateImageBounds();
      onImageBoundsChange(imageBounds);
    }
  }, [selectedImageIndex, onImageBoundsChange, imageLoaded]);

  const calculateImageBounds = () => {
    if (imgRef.current) {
      const imgRect = imgRef.current.getBoundingClientRect();
      return imgRect;
    }
    return null;
  };

  const handleImageClick = (e) => {
    if (imgRef.current) {
      const rect = imgRef.current.getBoundingClientRect();
      const x = (e.clientX - rect.left) / rect.width;
      const y = (e.clientY - rect.top) / rect.height;
      
      // Normalize coordinates to be between 0 and 1
      const normalizedX = Math.min(Math.max(x, 0), 1);
      const normalizedY = Math.min(Math.max(y, 0), 1);
      
      onImageClick({ x: normalizedX, y: normalizedY });
    }
  };


  const safeSelectedImageIndex = Math.min(selectedImageIndex, passedImages.length - 1);
  // console.log("PASSED IMAGES: ", passedImages)
  return (
    <div className="w-full h-full flex justify-center items-center">
    {passedImages.length > 0 ? (
      <div className="relative h-full">
        <img
          ref={imgRef}
          src={passedImages[safeSelectedImageIndex]?.imageUrl || emptyImage}
          alt={`Page ${pageNumber}`}
          className="w-full h-full object-contain"
          onLoad={() => setImageLoaded(true)}
          onClick={handleImageClick}
        />
      </div>
    ) : (
      <div className="flex justify-center items-center w-full h-full">
        {isGeneratingImages ? (
          <div>Generating images...</div>
        ) : (
          <div>No images available</div>
        )}
      </div>
    )}
  </div>
  );
};

export default ImageArea;
