import React from "react";
import { useAuth } from "../context/AuthContext";
import { loadStripe } from "@stripe/stripe-js";
import config from "../utils/config";
import { useLocation } from "react-router-dom";

const stripePromise = loadStripe(config.stripePublishableKey);

const PurchaseButton = ({ bookId, email }) => {
  const { user } = useAuth();
  const location = useLocation();

  const handleClick = async () => {
    try {
      // Save current path for return after purchase
      localStorage.setItem("purchaseReturnPath", location.pathname);

      const stripe = await stripePromise;

      // Get the email to use
      const customerEmail = user?.authenticated ? user.email : email;

      if (!customerEmail) {
        throw new Error("Email is required for checkout");
      }

      // Create checkout session
      const response = await fetch(
        `${config.apiUrl}/api/payments/create-checkout-session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...(user?.token && { Authorization: `Bearer ${user.token}` }),
          },
          body: JSON.stringify({
            book_id: bookId,
            email: customerEmail,
          }),
        }
      );

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.error || "Failed to create checkout session");
      }

      const { sessionId } = await response.json();

      // Redirect to Stripe Checkout
      const result = await stripe.redirectToCheckout({
        sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Error:", error);
      alert(error.message || "Failed to process purchase");
    }
  };

  return (
    <button
      onClick={handleClick}
      className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-blue-300 disabled:cursor-not-allowed"
      disabled={!email && !user?.authenticated}
    >
      Purchase Book
    </button>
  );
};

export default PurchaseButton;
