import React from "react";
import BookCover from "./BookCover";
import Page from "./Page";
import Navigation from "./Navigation";

const Book = ({
  story,
  isEditable,
  isGeneratingImages,
  viewMode,
  selectedImageIndex,
  onPageChange,
  currentPage,
  onImageClick,
}) => {
  if (!story || !story.pages) {
    return <div>Loading...</div>;
  }
  console.log("VIEW MODE IN BOOK: ", viewMode);
  const navigatePage = (newPage) => {
    console.log("NEW PAGE: ", newPage);
    console.log("ON PAGE CHANGE: ", onPageChange);
    if (newPage < 0 || newPage > story.pages.length + 1) return;

    let actualPage = newPage;
    if (viewMode === "double" && newPage > 0 && newPage < story.pages.length) {
      actualPage = newPage % 2 === 0 ? newPage - 1 : newPage;
    }
    onPageChange(actualPage);
  };

  const goNextPage = () => {
    if (currentPage === 0) return 1;
    if (viewMode === "double") {
      return Math.min(story.pages.length + 1, currentPage + 2);
    }
    return Math.min(story.pages.length + 1, currentPage + 1);
  };

  const goPreviousPage = () => {
    if (currentPage === 1) return 0;
    if (viewMode === "double") {
      return Math.max(0, currentPage - 2);
    }
    return Math.max(1, currentPage - 1);
  };

  const isEndPage = currentPage > story.pages.length;
  const isLastContentPage = currentPage === story.pages.length;

  const backCoverImage =
    story.coverImages?.find((img) => !img.is_front_cover && img.is_default) ||
    [];

  return (
    <div className="flex flex-col items-center max-w-[85vw] max-h-[90vh] max-w-4xl mx-auto">
      <div className="grow w-full overflow-hidden rounded-lg">
        {currentPage === 0 ? (
          <BookCover
            isEditable={isEditable}
            coverImage={story.coverImages || []}
            isGeneratingImages={isGeneratingImages}
            selectedImageIndex={selectedImageIndex}
            onImageClick={onImageClick}
          />
        ) : isEndPage ? (
          <div className="relative bg-gray-100 rounded shadow grow flex justify-center items-center w-full h-full">
            <BookCover
              isEditable={isEditable}
              coverImage={backCoverImage}
              isGeneratingImages={isGeneratingImages}
              selectedImageIndex={selectedImageIndex}
              onImageClick={onImageClick}
            />
          </div>
        ) : (
          <div className="flex flex-row h-full">
            <Page
              isEditable={isEditable}
              page={story.pages[currentPage - 1]}
              isGeneratingImages={isGeneratingImages}
              selectedImageIndex={selectedImageIndex}
              onImageClick={onImageClick}
            />
            {viewMode === "double" && !isLastContentPage && (
              <Page
                isEditable={isEditable}
                page={story.pages[currentPage]}
                isGeneratingImages={isGeneratingImages}
                selectedImageIndex={selectedImageIndex}
                onImageClick={onImageClick}
              />
            )}
          </div>
        )}
      </div>
      <Navigation
        currentPageNum={currentPage}
        totalPages={story.pages.length + 1}
        onNavigate={navigatePage}
        getNextPage={goNextPage}
        getPreviousPage={goPreviousPage}
        viewMode={viewMode}
      />
    </div>
  );
};

export default Book;
