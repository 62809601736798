import WhenIGrowUpCover from "../assets/bookcover.png";

const data = {
  "when-i-grow-up": {
    title: "When I Grow Up",
    description:
      "A personalized storybook that features the recipient's name and other custom elements, focusing on themes like growth, future hopes, or relationships.",
    ageRange: "5-10 years",
    pricing: "$19.99",
    personalizationDetails: {
      name: "Child's Name",
      age: "Child's Age",
      gender: "Child's Gender",
      addOns: ["Gift Wrap (+$4.99)", "Personalized Dedication Page (+$2.99)"],
    },
    images: [WhenIGrowUpCover, WhenIGrowUpCover, WhenIGrowUpCover],
    video: "/assets/when-i-grow-up/preview.mp4",
    reviews: [
      {
        quote: "This book brought so much joy to my child!",
        author: "Jordan Lee",
        role: "Parent",
        rating: 5,
      },
      {
        quote: "A unique and heartfelt gift.",
        author: "Sam Rivera",
        role: "Happy Dad",
        rating: 5,
      },
      {
        quote: "My kids loved seeing themselves in the story.",
        author: "Alex Kim",
        role: "Educator",
        rating: 5,
      },
    ],
  },
};

export const getBookData = (bookId) => {
  return data[bookId];
};
