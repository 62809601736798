import React, { useState, useEffect } from "react";
import { useStory } from "../context/StoryContext";
import CollapsibleSidebar from "../components/CollapsibleSidebar";
import Book from "../components/Book";
import ManagePhotosComponent from "../components/ManagePhotosComponent";
import {
  ArrowLeftIcon,
  ArrowPathIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import { useBookPage } from "hooks/useBookPage";
import {
  Button,
  IconButton,
  ToggleButton,
  Section,
  Checkbox,
} from "../components/uicomponents";

const BookPage = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const { dispatch } = useStory();
  const {
    isGenerating,
    isGenerateImagePrompt,
    storyState,
    selectedImages,
    userPhotosRequestId,
    generateMode,
    viewMode,
    isCoverPage,
    sortedCurrentImages,
    getCurrentPagePrompt,
    generateImages,
    handleImageToggle,
    toggleGenerateMode,
    handlePageChange,
    imageNavigation,
    regeneratePrompt,
    setDefaultImage,
    handlePromptChange,
    handleFaceSizeChange,
    handleFacePositioninChange,
    handleAngleRatioChange,
    handleControlnetStrengthChange,
    setuserPhotosRequestId,
    navigate,
    handleNewSeedToggled,
  } = useBookPage();

  const handleGenerateImages = () => {
    let imagePromptsToGenerate = [];
    if (generateMode === "selected") {
      imagePromptsToGenerate = Object.values(selectedImages).map(
        ({ pageNumber, imageIndex }) => {
          console.log("PAGE NUMBER: ", pageNumber);
          const page = storyState.pages.find(
            (page) => page.pageNumber === pageNumber
          );
          console.log("PAGE: ", page);
          console.log("SORTED CURRENT IMAGES: ", sortedCurrentImages)
          const sortedImages = [
            ...page.images?.filter((image) => image.is_default),
            ...page.images?.filter((image) => !image.is_default),
          ];
          console.log("SORTED IMAGES: ", sortedImages);
          const reuseSeed = page.newSeed ?? true;
          return {
            imagePrompt: page.userPrompt || sortedImages[0].imagePrompt, //page.images[0].imagePrompt,
            imageId: reuseSeed 
              ? sortedImages[0].imageId //page.images[0].imageId 
              : sortedImages[0].imageUrl //page.images[0].imageUrl
              ? undefined 
              : sortedImages[0].imageId, //page.images[0].imageId,            
            pageNumber: pageNumber,
            bookId: storyState.bookId,
            faceSize: page.faceSize || sortedImages[0].faceSize, //page.images[0].faceSize,
            facePositioning: page.facePositioning || sortedImages[0].facePositioning, //page.images[0].facePositioning,
            angleRatio: page.angleRatio || sortedImages[0].angleRatio, //page.images[0].angleRatio,
            controlnetStrength: page.controlnetStrength || sortedImages[0].controlnetStrength, //page.images[0].controlnetStrength,
            reuseSeed: reuseSeed,
          };
        }
      );

      console.log(
        "SELECTED IMAGE PROMPTS TO GENERATE: ",
        imagePromptsToGenerate
      );
      if (imagePromptsToGenerate.length === 0) {
        alert("Please select images to regenerate");
        return;
      }
    } else {
      imagePromptsToGenerate = storyState.pages.map((page) => {
      const sortedImages = [
        ...page.images?.filter((image) => image.is_default),
        ...page.images?.filter((image) => !image.is_default),
      ];
      console.log("SORTED IMAGES: ", sortedImages);
        return {
        imagePrompt: sortedImages[0].imagePrompt,
        imageId: sortedImages[0].imageId,
        pageNumber: page.pageNumber,
        bookId: storyState.bookId,
        faceSize: sortedImages[0].faceSize,
        facePositioning: sortedImages[0].facePositioning,
        angleRatio: sortedImages[0].angleRatio,
        controlnetStrength: sortedImages[0].controlnetStrength,
        reuseSeed: page.newSeed ?? true,
      }
    });
    }
    console.log(imagePromptsToGenerate);
    generateImages(
      imagePromptsToGenerate,
    );
  };
  console.log(storyState.pages)
  const handleRegenerateCurrentPageImage = () => {
    const isDoubleLayout = storyState.settings.imageLayout === "double";
    const currentPageNumber = storyState.currentPage;

    let imagePromptsToGenerate = [];

    const currentImage = sortedCurrentImages[selectedImageIndex];

    if (isDoubleLayout) {
      const leftPagePrompt = {
        imagePrompt: currentImage.imagePrompt,
        pageNumber: currentPageNumber,
        bookId: storyState.bookId,
      };

      const rightPagePrompt = {
        imagePrompt: currentImage.imagePrompt,
        pageNumber: currentPageNumber + 1,
        bookId: storyState.bookId,
      };

      imagePromptsToGenerate = [leftPagePrompt, rightPagePrompt];
    } else {
      const singlePagePrompt = {
        imagePrompt: currentImage.imagePrompt,
        pageNumber: currentPageNumber,
        bookId: storyState.bookId,
      };

      imagePromptsToGenerate = [singlePagePrompt];
    }
    console.log(imagePromptsToGenerate);
    generateImages(
      imagePromptsToGenerate,
    );
  };

  const handleImageNavigation = (direction, side = "left") => {
    const newIndex = imageNavigation(direction, selectedImageIndex, side);
    setSelectedImageIndex(newIndex);
    dispatch({
      type: "UPDATE_SELECTED_IMAGE_INDEX",
      payload: { selectedImageIndex: newIndex, side },
    });
  };

  const handleRegeneratePrompt = () => {
    regeneratePrompt(selectedImageIndex);
  };

  const handleSetDefaultImage = () => {
    setDefaultImage(selectedImageIndex, false);
    handleImageNavigation("first", selectedImageIndex);
  };

  const leftSidebarContent = (
    <>
      <p> Select Character </p>
      <br />
      <ManagePhotosComponent
        onSelect={setuserPhotosRequestId}
        userPhotosRequestId={userPhotosRequestId}
        showDeleteButton={false}
      />
      <br />
      <div className="mb-6 space-y-4">
        <ToggleButton
          label="Generate Mode"
          options={[
            { value: "all", label: "All" },
            { value: "selected", label: "Selected" },
          ]}
          value={generateMode}
          onChange={toggleGenerateMode}
        />
      </div>
      <Section title="Prompt">
        <div className="space-y-2">
          <button
            onClick={handleRegeneratePrompt}
            disabled={isGenerateImagePrompt}
            className={`px-2 py-2 flex items-center justify-center space-x-2 ${
              isGenerateImagePrompt
                ? "bg-gray-300 text-gray-400 cursor-not-allowed"
                : "bg-blue-500 text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            } rounded-md transition duration-200`}
            aria-label="Regenerate prompt"
          >
            {isGenerateImagePrompt && (
              <ArrowPathIcon className="h-5 w-5 animate-spin" />
            )}
            <span>
              {isGenerateImagePrompt ? "Generating..." : "Generate Prompt"}
            </span>
          </button>

          <p className="text-md font-medium text-gray-700 mt-4">Edit Prompt:</p>
          <textarea
            value={getCurrentPagePrompt()}
            onChange={(e) => handlePromptChange(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
            placeholder="Enter new image prompt"
            rows={5}
          />
        </div>
      </Section>
      <Checkbox
        checked={storyState.pages[storyState.currentPage - 1]?.newSeed ?? true}
        onChange={() => handleNewSeedToggled(!storyState.pages[storyState.currentPage - 1]?.newSeed)}
        label="Reuse Seed"
      />
      <Section title="Face Controls">
        <div className="space-y-4">
          <div>
            <label
              htmlFor="faceSize"
              className="block text-sm font-medium text-gray-700"
            >
              Face Size
            </label>
            <input
              type="text"
              id="faceSize"
              value={storyState.pages[storyState.currentPage - 1]?.faceSize || ""}
              onChange={(e) => handleFaceSizeChange(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter a value between 0 and 100"
            />
          </div>
          <div>
            <label
              htmlFor="facePositionining"
              className="block text-sm font-medium text-gray-700"
            >
              Face Positioning
            </label>
            <input
              type="text"
              id="facePositionining"
              value={storyState.pages[storyState.currentPage - 1]?.facePositioning || ""}
              onChange={(e) => handleFacePositioninChange(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter a value between 0 and 100"
            />
          </div>
          <div>
            <label
              htmlFor="angleRatio"
              className="block text-sm font-medium text-gray-700"
            >
              Angle Ratio
            </label>
            <input
              type="text"
              id="angleRatio"
              value={storyState.pages[storyState.currentPage - 1]?.angleRatio || ""}
              onChange={(e) => handleAngleRatioChange(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter a value between 0 and 100"
            />
          </div>
          <div>
            <label
              htmlFor="controlnetStrength"
              className="block text-sm font-medium text-gray-700"
            >
              Likeness Strength
            </label>
            <input
              type="text"
              id="controlnetStrength"
              value={storyState.pages[storyState.currentPage - 1]?.controlnetStrength || ""}
              onChange={(e) => handleControlnetStrengthChange(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="Enter a value between 0 and 100"
            />
          </div>
        </div>
      </Section>
      <Checkbox
        checked={sortedCurrentImages[selectedImageIndex]?.isSelected || false}
        onChange={() =>
          handleImageToggle(
            storyState.currentPage,
            sortedCurrentImages[selectedImageIndex].imageId,
            false,
            storyState.currentPage === 0
          )
        }
        label="Select for Regeneration"
      />
    </>
  );

  const rightSidebarContent = (
    <>
      <Section title="Image Actions">
        <div className="space-y-3">
          <Button
            onClick={handleSetDefaultImage}
            disabled={selectedImageIndex === 0}
            icon={StarIcon}
            fullWidth
          >
            Make Default Image
          </Button>
        </div>
        <div className="mt-4">
          <Button
            onClick={handleRegenerateCurrentPageImage}
            icon={ArrowPathIcon}
            fullWidth
            disabled={isGenerating}
          >
            Regenerate Image
          </Button>
        </div>
      </Section>
      <Section title="Image Versions">
        <div className="flex justify-between items-center">
          <IconButton
            onClick={() => handleImageNavigation("prev")}
            icon={ChevronLeftIcon}
          />
          <span className="text-lg font-semibold">
            {selectedImageIndex + 1} / {sortedCurrentImages.length}
          </span>
          <IconButton
            onClick={() => handleImageNavigation("next")}
            icon={ChevronRightIcon}
          />
        </div>
      </Section>
      <Section title="Prompt">
        <div className="space-y-2">
          <p className="text-gray-600 bg-gray-100 p-2 rounded">
            {sortedCurrentImages[selectedImageIndex]?.imagePrompt ||
              "No prompt available"}
          </p>
        </div>
      </Section>
      <Section title="Face Values">
        <div className="space-y-4">
          <div>
            <label
              htmlFor="faceSize"
              className="block text-sm font-medium text-gray-700"
            >
              Face Size
            </label>
            <p
              id="faceSize"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm bg-gray-100 p-2"
            >
              {sortedCurrentImages[selectedImageIndex]?.faceSize || ""}
            </p>
          </div>
          <div>
            <label
              htmlFor="facePositioning"
              className="block text-sm font-medium text-gray-700"
            >
              Face Positioning
            </label>
            <p
              id="facePositioning"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm bg-gray-100 p-2"
            >
              {sortedCurrentImages[selectedImageIndex]?.facePositioning || ""}
            </p>
          </div>
          <div>
            <label
              htmlFor="angleRatio"
              className="block text-sm font-medium text-gray-700"
            >
              Angle Ratio
            </label>
            <p
              id="angleRatio"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm bg-gray-100 p-2"
            >
              {sortedCurrentImages[selectedImageIndex]?.angleRatio}
            </p>
          </div>
          <div>
            <label
              htmlFor="controlnetStrength"
              className="block text-sm font-medium text-gray-700"
            >
              Likeness Strength
            </label>
            <p
              id="controlnetStrength"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm bg-gray-100 p-2"
            >
              {sortedCurrentImages[selectedImageIndex]?.controlnetStrength}
            </p>
          </div>
        </div>
      </Section>
    </>
  );

  const leftSidebarActions = [
    {
      label: "Back to Cover Editor",
      onClick: () => navigate(`/cover-editor/${storyState.bookId}`),
      icon: ArrowLeftIcon,
      className: "bg-gray-400 hover:bg-gray-500 focus:ring-gray-300",
      disabled: false,
    },
    {
      label: "Generate Images",
      onClick: handleGenerateImages,
      loading: isGenerating,
      icon: ArrowPathIcon,
      className: "bg-blue-500 hover:bg-blue-600 focus:ring-blue-500",
      disabled:
        generateMode === "selected" && Object.keys(selectedImages).length === 0,
    },
  ];

  const rightSidebarActions = [];

  return (
    <div className="flex w-full h-screen bg-gray-50">
      {!isCoverPage && (
        <CollapsibleSidebar
          actions={leftSidebarActions}
          title="Image Generator"
        >
          {leftSidebarContent}
        </CollapsibleSidebar>
      )}
      <div className="grow flex overflow-hidden">
        <Book
          story={storyState}
          isEditable={true}
          isGeneratingImages={isGenerating}
          viewMode={viewMode}
          selectedImageIndex={selectedImageIndex}
          onPageChange={handlePageChange}
          currentPage={storyState.currentPage}
        />
        {!isCoverPage && (
          <CollapsibleSidebar
            position="right"
            actions={rightSidebarActions}
            title="Image Controls"
          >
            {rightSidebarContent}
          </CollapsibleSidebar>
        )}
      </div>
    </div>
  );
};

export default BookPage;