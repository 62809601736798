import { STORY_ACTIONS } from "../constants/actionTypes";

export const handlePageUpdate = {
  setStoryState: (state, payload) => ({
    ...state,
    pages: payload.pages,
    storyText: payload.storyText,
    title: payload.title,
    bookId: payload.bookId,
    authorId: payload.authorId,
    email: payload.email,
    coverImages: payload.coverImages,
    titleImages: payload.titleImages,
    imageLayout: payload.settings.image_layout,
    is_paid: payload.is_paid,
    generation_status: payload.generation_status || {
      total: 0,
      completed: 0,
      generating: 0,
      failed: 0,
      is_complete: false,
      progress: 0,
      statuses: [],
    },
    userPhotosRequestId:
      payload.userPhotosRequestId ||
      payload.settings.user_photos_request_id ||
      state.userPhotosRequestId,
    settings: payload.settings,
    hasChanges: false,
  }),

  updatePageAttribute: (state, { type, payload }) => {
    const attributeMap = {
      [STORY_ACTIONS.UPDATE_FACE_POSITION]: "facePositioning",
      [STORY_ACTIONS.UPDATE_ANGLE_RATIO]: "angleRatio",
      [STORY_ACTIONS.UPDATE_CONTROLNET_STRENGTH]: "controlnetStrength",
      [STORY_ACTIONS.UPDATE_FACE_SIZE]: "faceSize",
      [STORY_ACTIONS.TOGGLE_NEW_SEED]: "newSeed",
      [STORY_ACTIONS.UPDATE_USER_PROMPT]: "userPrompt",
    };

    const attribute = attributeMap[type];
    if (!attribute) return state;

    console.log("Updating prompt:", {
      type,
      payload,
      attribute,
      currentPage: payload.pageNumber,
    });

    if (payload.pageNumber === 0) {
      return {
        ...state,
        coverImages: state.coverImages.map((image) => ({
          ...image,
          userPrompt: payload.userPrompt,
        })),
      };
    }

    return {
      ...state,
      pages: state.pages.map((page) =>
        page.pageNumber === payload.pageNumber
          ? {
              ...page,
              [attribute]:
                attribute === "newSeed" ? !page.newSeed : payload[attribute],
            }
          : page
      ),
    };
  },
};

export const handleImageOperations = {
  toggleSelection: (state, payload) => {
    if (payload.isCover) {
      return {
        ...state,
        coverImages: toggleCoverImageSelection(state.coverImages, payload),
        hasChanges: true,
      };
    }
    return {
      ...state,
      pages: toggleImageSelection(state.pages, payload),
      hasChanges: true,
    };
  },

  updatePrompt: (state, payload) => {
    const isCover = payload.pageNumber === 0;
    return {
      ...state,
      ...(isCover
        ? { coverImages: updateCoverImagePrompt(state.coverImages, payload) }
        : { pages: updatePagePrompt(state.pages, payload) }),
      hasChanges: true,
    };
  },

  setDefaultImage: (state, payload) => {
    const { pageNumber, imageId, rightImageId, layout } = payload;
    return {
      ...state,
      pages: state.pages.map((page, idx) => {
        if (
          idx === pageNumber - 1 ||
          (layout === "double" && idx === pageNumber)
        ) {
          return {
            ...page,
            images: page.images.map((image) => ({
              ...image,
              is_default:
                image.imageId === (idx === pageNumber ? rightImageId : imageId),
            })),
          };
        }
        return page;
      }),
      hasChanges: true,
    };
  },

  removeImage: (state, { pageNumber, imageId }) => {
    if (pageNumber === 0) {
      return {
        ...state,
        coverImages: state.coverImages.filter(
          (image) => image.imageId !== imageId
        ),
        hasChanges: true,
      };
    }
    return {
      ...state,
      pages: state.pages.map((page) =>
        page.pageNumber === pageNumber
          ? {
              ...page,
              images: page.images.filter((image) => image.imageId !== imageId),
            }
          : page
      ),
      hasChanges: true,
    };
  },
};

export const handlePageManagement = {
  handlePageAction: (state, { type, payload }) => {
    switch (type) {
      case STORY_ACTIONS.ADD_PAGE:
        return {
          ...state,
          pages: [
            ...state.pages,
            {
              pageNumber: state.pages.length + 1,
              text_blocks: [{ content: "" }],
              images: [{ imagePrompt: "", imageUrl: "" }],
            },
          ],
        };

      case STORY_ACTIONS.DELETE_PAGE:
        return {
          ...state,
          pages: state.pages
            .filter((page) => page.pageNumber !== payload)
            .map((page, index) => ({
              ...page,
              pageNumber: index + 1,
            })),
          hasChanges: true,
        };

      case STORY_ACTIONS.REORDER_PAGES:
        return {
          ...state,
          pages: payload,
          hasChanges: true,
        };

      default:
        return state;
    }
  },

  addNewTextBlock: (state, payload) => ({
    ...state,
    pages: state.pages.map((page) =>
      page.pageId === payload.pageId
        ? {
            ...page,
            text_blocks: [...page.text_blocks, payload.textBlock],
          }
        : page
    ),
    hasChanges: true,
  }),
};

export const handleCoverImage = (state, image) => {
  const existingIndex = state.coverImages.findIndex(
    (img) => img.imageId === image.imageId
  );
  const newCoverImages =
    existingIndex !== -1
      ? [
          ...state.coverImages.slice(0, existingIndex),
          image,
          ...state.coverImages.slice(existingIndex + 1),
        ]
      : [image, ...state.coverImages];

  return { ...state, coverImages: newCoverImages, hasChanges: true };
};

export const handlePageImage = (state, pageNumber, image) => {
  const newPages = state.pages.map((page, i) => {
    if (i !== pageNumber - 1) return page;

    const pageImages = page.images || [];
    const existingIndex = pageImages.findIndex(
      (img) => img.imageId === image.imageId
    );

    const newPageImages =
      existingIndex !== -1
        ? [
            ...pageImages.slice(0, existingIndex),
            image,
            ...pageImages.slice(existingIndex + 1),
          ]
        : [image, ...pageImages];

    return { ...page, images: newPageImages };
  });

  return { ...state, pages: newPages, hasChanges: true };
};

export const addOrReplaceImage = (state, { pageNumber, image }) => {
  return pageNumber === 0
    ? handleCoverImage(state, image)
    : handlePageImage(state, pageNumber, image);
};

export const toggleImageSelection = (pages, { pageNumber, imageId }) => {
  return pages.map((page) =>
    page.pageNumber !== pageNumber
      ? page
      : {
          ...page,
          images: page.images.map((image) =>
            image.imageId === imageId
              ? { ...image, isSelected: !image.isSelected }
              : image
          ),
        }
  );
};

export const toggleCoverImageSelection = (coverImages, { imageId }) => {
  return coverImages.map((image) =>
    image.imageId === imageId
      ? { ...image, isSelected: !image.isSelected }
      : image
  );
};

export const updatePagePrompt = (pages, { pageNumber, newPrompt }) => {
  return pages.map((page, i) =>
    i !== pageNumber - 1 ? page : { ...page, userPrompt: newPrompt }
  );
};

export const updateCoverImagePrompt = (coverImages, { newPrompt }) => {
  return coverImages.map((image) => ({ ...image, userPrompt: newPrompt }));
};

export const updatePageTextBlocks = (pages, { pageId, updatedTextBlock }) => {
  return pages.map((page) =>
    page.pageId !== pageId
      ? page
      : {
          ...page,
          text_blocks: page.text_blocks.map((block) =>
            block.id === updatedTextBlock.id ? updatedTextBlock : block
          ),
        }
  );
};

export const deleteTextBlock = (pages, { pageId, textBlockId }) => {
  return pages.map((page) =>
    page.pageId !== pageId
      ? page
      : {
          ...page,
          text_blocks: page.text_blocks.filter(
            (block) => block.id !== textBlockId
          ),
        }
  );
};

export const handleCoverImageUpdate = {
  setCover: (state, payload) => {
    return {
      ...state,
      coverImages: state.coverImages.map((image) => ({
        ...image,
        isCover: image.imageId === payload.imageId,
      })),
      hasChanges: true,
    };
  },
};
