import React, { useState } from 'react';
import { PlayIcon, StarIcon } from '@heroicons/react/24/outline';

function ProductDisplay({ bookData }) {
  const [selectedImage, setSelectedImage] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  return (
    <div className="container mx-auto px-4 py-8">
      {/* --- Video/Image Preview --- */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <div className="aspect-[4/3] relative rounded-lg overflow-hidden bg-white shadow-lg">
            {showVideo ? (
              <div className="relative w-full h-full">
                <video
                  src={bookData.video}
                  className="w-full h-full object-cover"
                  controls={isPlaying}
                  autoPlay={isPlaying}
                />
                {!isPlaying && (
                  <button
                    onClick={() => setIsPlaying(true)}
                    className="absolute inset-0 flex items-center justify-center bg-black/30 hover:bg-black/40 transition-colors"
                  >
                    <PlayIcon className="w-16 h-16 text-white" />
                  </button>
                )}
              </div>
            ) : (
              <img
                src={bookData.images[selectedImage]}
                alt={`${bookData.title} preview ${selectedImage + 1}`}
                className="w-full h-full object-cover"
              />
            )}
          </div>

          {/* --- Thumbnails --- */}
          <div className="flex gap-2 overflow-x-auto py-2">
            {bookData.images.map((img, idx) => (
              <button
                key={idx}
                onClick={() => {
                  setSelectedImage(idx);
                  setShowVideo(false);
                }}
                className={`flex-shrink-0 w-20 h-20 rounded-md overflow-hidden border-2 transition-colors ${
                  selectedImage === idx && !showVideo ? 'border-blue-500' : 'border-transparent'
                }`}
              >
                <img src={img} alt={`Thumbnail ${idx + 1}`} className="w-full h-full object-cover" />
              </button>
            ))}
            <button
              onClick={() => {
                setShowVideo(true);
                setIsPlaying(false);
              }}
              className={`flex-shrink-0 w-20 h-20 rounded-md overflow-hidden border-2 transition-colors relative ${
                showVideo ? 'border-blue-500' : 'border-transparent'
              }`}
            >
              <img
                src={bookData.images[0]}
                alt="Video thumbnail"
                className="w-full h-full object-cover opacity-80"
              />
              <PlayIcon className="absolute inset-0 m-auto w-8 h-8 text-white" />
            </button>
          </div>
        </div>

        {/* --- Book Details (Title, Price, etc.) --- */}
        <div className="space-y-6">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">{bookData.title}</h1>
            <div className="flex items-center gap-2 text-sm text-gray-600">
              <div className="flex">
                {[...Array(5)].map((_, i) => (
                  <StarIcon key={i} className="w-4 h-4 text-yellow-400" />
                ))}
              </div>
              <span>({bookData.reviews.length} reviews)</span>
              <span>•</span>
              <span>Age {bookData.ageRange}</span>
            </div>
          </div>

          <div className="border-t border-b py-4">
            <div className="text-3xl font-bold text-gray-900 mb-2">
              {bookData.pricing}
              <span className="text-sm font-normal text-gray-500 ml-2">Free shipping</span>
            </div>
            <p className="text-gray-600">{bookData.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDisplay;