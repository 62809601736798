import { useState } from "react";
import { useStory } from "../context/StoryContext";
import useImageService from "../services/imageService";
import { regenerateImagePrompts } from "../services/storyService";
import {
  getSortedImages,
  findPageByNumber,
  getCoverImage,
  withLoading,
  parsePromptNumbers,
  getImagesByMode,
  apiRequest,
  getPages,
} from "utils/bookHelpers";
import { useAuth } from "context/AuthContext";

export const useBookImages = () => {
  const { user } = useAuth();
  const [isGenerating, setIsGenerating] = useState(false);
  const [isColorCorrecting, setIsColorCorrecting] = useState(false);
  const [isAdjusting, setIsAdjusting] = useState(false);
  const [isGeneratingPages, setIsGeneratingPages] = useState(false);
  const [isGenerateImagePrompt, setIsGenerateImagePrompt] = useState(false);

  const [selectedImages, setSelectedImages] = useState({});
  const [generateMode, setGenerateMode] = useState("all");
  const [clickedCoords, setClickedCoords] = useState({ x: 0, y: 0 });

  const imageService = useImageService();
  const { storyState, dispatch } = useStory();

  const { leftPage, rightPage } = getPages(storyState);

  const sortedCurrentImages = getSortedImages(leftPage.images);
  const sortedRightImages = getSortedImages(rightPage.images || []);

  const getCurrentPagePrompt = () => {
    if (storyState.currentPage === 0) {
      const defaultCover = getCoverImage(storyState);
      return (
        defaultCover?.userPrompt || storyState.coverImages[0]?.userPrompt || ""
      );
    }
    return storyState.pages?.[storyState.currentPage - 1]?.userPrompt || "";
  };

  const generateImages = async (imagePrompts) => {
    await withLoading(setIsGenerating, async () => {
      const layout = storyState.settings?.imageLayout || "single";
      const updatedPrompts = imagePrompts.map(parsePromptNumbers);

      if (layout === "double") {
        await imageService.batchGenerateImages(
          updatedPrompts,
          storyState.userPhotosRequestId ||
            storyState.settings?.userPhotosRequestId,
          layout,
          updateImageCallbackForDoubleLayout,
          setIsGenerating,
          storyState.bookId
        );
      } else {
        await imageService.batchGenerateImages(
          updatedPrompts,
          storyState.userPhotosRequestId ||
            storyState.settings?.userPhotosRequestId,
          layout,
          updateImageCallback,
          setIsGenerating,
          storyState.bookId
        );
      }

      dispatch({ type: "CLEAR_ALL_SELECTED_IMAGES" });
      setSelectedImages({});
    });
  };

  const generateImagesNewMethod = async (imagePrompts) => {
    await withLoading(setIsGenerating, async () => {
      const updatedPrompts = imagePrompts.map((prompt) => {
        const faceSize = parseFloat(prompt.faceSize);
        const controlnetStrength = parseFloat(prompt.controlnetStrength);
        return {
          ...prompt,
          faceSize:
            !isNaN(faceSize) && faceSize >= 0 && faceSize <= 100
              ? faceSize
              : null,
          controlnetStrength:
            !isNaN(controlnetStrength) && controlnetStrength <= 100
              ? controlnetStrength
              : null,
        };
      });

      await imageService.imageGenSingle(
        updatedPrompts,
        updateImageCallback,
        setIsGenerating
      );

      dispatch({ type: "CLEAR_ALL_SELECTED_IMAGES" });
      setSelectedImages({});
    });
  };

  const updateImageCallbackForDoubleLayout = (
    urls,
    pageNumber,
    imagePrompts,
    ids,
    actual_image_urls
  ) => {
    urls.forEach((url, index) => {
      const newImage = {
        imageId: ids[index],
        imagePrompt: imagePrompts[index],
        is_default: true,
        layout_info: {
          position: { x: "10%", y: "20%", width: "80%", height: "60%" },
          zIndex: 1,
          fullPageOverlay: false,
        },
        imageUrl: url,
        actual_image_url: actual_image_urls[index],
        pageNumber: pageNumber + index,
      };

      dispatch({
        type: "ADD_OR_REPLACE_IMAGE",
        payload: { pageNumber: pageNumber + index, image: newImage },
      });

      const pageImages = storyState.pages?.[pageNumber + index - 1];
      if (pageImages?.length > 0) {
        const currImage = pageImages.images[0];
        const storedPrompt = currImage.storedPrompt;
        dispatch({
          type: "UPDATE_IMAGE_PROMPT",
          payload: {
            pageNumber: pageNumber + index,
            imageIndex: 1,
            newPrompt: storedPrompt,
            oldPrompt: "",
          },
        });
      }
    });
    setIsGenerating(false);
  };

  const updateImageCallback = (
    url,
    pageNumber,
    imagePrompt,
    id,
    actual_image_url
  ) => {
    const isCover = pageNumber === 0;
    const pageImages = isCover
      ? storyState.coverImages
      : storyState.pages?.[pageNumber - 1]?.images || [];

    const newImage = {
      imageId: id,
      imagePrompt,
      is_default: true,
      layout_info: {
        position: { x: "10%", y: "20%", width: "80%", height: "60%" },
        zIndex: 1,
        fullPageOverlay: false,
      },
      imageUrl: url,
      actual_image_url,
      pageNumber,
    };

    dispatch({
      type: "ADD_OR_REPLACE_IMAGE",
      payload: { pageNumber, image: newImage },
    });

    if (pageImages.length > 0) {
      const currImage = pageImages[0];
      const storedPrompt = currImage.storedPrompt;
      dispatch({
        type: "UPDATE_IMAGE_PROMPT",
        payload: {
          pageNumber,
          imageIndex: 1,
          newPrompt: storedPrompt,
          oldPrompt: "",
        },
      });
    }
    setIsGenerating(false);
  };

  const handleColorCorrect = async () => {
    console.log("Color correct handler called");
    const imagesToColorCorrect = getImagesByMode({
      storyState,
      generateMode,
      selectedImages,
      filterFn: (page) => page.pageNumber % 2 === 0,
      buildImageData: (pageNumber) => {
        if (pageNumber <= 0) {
          const coverImage = getCoverImage(storyState);
          const sorted = getSortedImages(storyState.coverImages);
          if (!coverImage || !sorted.length) return null;
          return {
            request_id: storyState.settings?.userPhotosRequestId,
            user_id: storyState.userId || storyState.email,
            user_email: storyState.email,
            photo_url: sorted[0].imageUrl,
            image_folder_uuid: storyState.settings?.imageFolderUuid,
            gender: storyState.settings?.gender,
            age: storyState.settings?.age,
            identifier: sorted[0].imageTemplate,
            page_id: 0,
            book_id: storyState.bookId,
          };
        } else {
          const page = findPageByNumber(storyState.pages, pageNumber);
          if (!page || !page.images) return null;
          const sorted = getSortedImages(page.images);
          if (!sorted.length) return null;
          return {
            request_id: storyState.settings?.userPhotosRequestId,
            user_id: storyState.userId || storyState.email,
            user_email: storyState.email,
            photo_url: sorted[0].imageUrl,
            image_folder_uuid: storyState.settings?.imageFolderUuid,
            gender: storyState.settings?.gender,
            age: storyState.settings?.age,
            identifier: sorted[0].imageTemplate,
            page_id: page.pageId,
            book_id: storyState.bookId,
          };
        }
      },
    });

    console.log("Images to color correct:", imagesToColorCorrect);

    if (!imagesToColorCorrect.length) {
      alert("Please select images to color correct.");
      return;
    }

    await withLoading(setIsColorCorrecting, async () => {
      for (const imageData of imagesToColorCorrect) {
        console.log("Sending color correct request for:", imageData);
        await apiRequest("image/colorcorrect", "POST", imageData);
      }
    });
  };

  const handleAdjustImage = async (
    faceSize,
    selectedImageIndex,
    deleteOriginal = false
  ) => {
    const imageId = sortedRightImages?.[selectedImageIndex]?.imageId;
    if (!imageId) {
      alert("No image selected to adjust");
      return;
    }

    let pageId;
    if (storyState.currentPage === 0) {
      pageId = 0;
    } else {
      const currentPageObj = findPageByNumber(
        storyState.pages,
        storyState.currentPage + 1
      );
      pageId = currentPageObj?.pageId;
    }

    await withLoading(setIsAdjusting, async () => {
      let coordsToSend;
      if (
        clickedCoords.x === 0 &&
        clickedCoords.y === 0 &&
        sortedRightImages?.[selectedImageIndex]?.facePositioning
      ) {
        const facePos = sortedRightImages[selectedImageIndex].facePositioning
          ?.split(",")
          .map(Number);
        coordsToSend = { x: facePos?.[0], y: facePos?.[1] };
      } else {
        coordsToSend = clickedCoords;
      }

      await apiRequest(`image/adjust/${imageId}`, "POST", {
        faceSize,
        book_id: storyState.bookId,
        request_id: storyState.settings?.userPhotosRequestId,
        page_id: pageId,
        facePositioning: coordsToSend,
        prompt: getCurrentPagePrompt(),
        userId: storyState.authorId,
        email: storyState.email,
        deleteOriginal,
      });
    });
  };

  const regeneratePrompt = async (selectedImageIndex) => {
    setIsGenerateImagePrompt(true);
    try {
      const pageNumber = storyState.currentPage;
      const response = await regenerateImagePrompts(storyState, [pageNumber]);
      const updatedPrompt = response?.[0];
      const newPrompt = updatedPrompt?.prompt || "";

      dispatch({
        type: "UPDATE_IMAGE_PROMPT",
        payload: {
          pageNumber,
          imageIndex: selectedImageIndex,
          newPrompt,
          oldPrompt: sortedCurrentImages?.[selectedImageIndex]?.imagePrompt,
        },
      });
      handlePromptChange(newPrompt);
    } catch (error) {
      console.error("Failed to regenerate prompt:", error);
    } finally {
      setIsGenerateImagePrompt(false);
    }
  };

  const handlePromptChange = (newPrompt) => {
    dispatch({
      type: "UPDATE_USER_PROMPT",
      payload: { pageNumber: storyState.currentPage, userPrompt: newPrompt },
    });
  };

  const handleGenerateImages = () => {
    const imagePromptsToGenerate = buildImagePromptsForGeneration({
      storyState,
      generateMode,
      selectedImages,
    });

    if (!imagePromptsToGenerate.length) {
      alert("Please select images to regenerate");
      return;
    }

    const groupedPrompts = imagePromptsToGenerate.reduce((acc, prompt) => {
      if (!prompt.identifier) return acc;
      if (!acc[prompt.identifier]) acc[prompt.identifier] = [];
      acc[prompt.identifier].push(prompt);
      return acc;
    }, {});

    const uniquePrompts = Object.entries(groupedPrompts).map(
      ([identifier, prompts]) =>
        prompts.reduce((merged, current) => ({
          ...merged,
          ...current,
          imagePrompt: current.imagePrompt || merged.imagePrompt,
          faceSize: current.faceSize || merged.faceSize,
          facePositioning: current.facePositioning || merged.facePositioning,
          pageId:
            current.pageId === "cover"
              ? "cover"
              : Math.max(merged.pageId, current.pageId),
          pageNumber:
            current.pageId === "cover"
              ? 0
              : current.pageId > merged.pageId
              ? current.pageNumber
              : merged.pageNumber,
        }))
    );

    const payload = uniquePrompts.map((prompt) => ({
      request_id: storyState.settings?.userPhotosRequestId,
      user_id: storyState.userId || storyState.email,
      user_email: storyState.email,
      hair_length: storyState.settings?.hairLength,
      gender: storyState.settings?.gender,
      age: storyState.settings?.age,
      identifier: prompt.identifier,
      kps_scale: prompt.faceSize,
      prompt: prompt.imagePrompt,
      page_id: prompt.pageId === "cover" ? 0 : prompt.pageId + 1,
      book_id: prompt.bookId,
      face_positioning: prompt.facePositioning,
    }));

    generateImagesNewMethod(payload);
  };

  function buildImagePromptsForGeneration({
    storyState,
    generateMode,
    selectedImages,
  }) {
    return getImagesByMode({
      storyState,
      generateMode,
      selectedImages,
      filterFn: null,
      buildImageData: (pageNumber) => {
        if (pageNumber <= 0) {
          const coverImg = getCoverImage(storyState);
          const sorted = getSortedImages(storyState.coverImages);
          if (!coverImg || !sorted.length) return null;

          return {
            imagePrompt: coverImg.userPrompt || coverImg.imagePrompt,
            pageNumber: 0,
            pageId: "cover",
            bookId: storyState.bookId,
            identifier: sorted[0].imageTemplate,
            faceSize: coverImg.faceSize || sorted[0].faceSize,
            facePositioning:
              coverImg.facePositioning || sorted[0].facePositioning,
          };
        } else {
          const page = findPageByNumber(storyState.pages, pageNumber - 1);
          if (!page || !page.images) return null;
          const sorted = getSortedImages(page.images);
          if (!sorted.length) return null;

          return {
            imagePrompt: page.userPrompt || sorted[0].imagePrompt,
            pageNumber: page.pageNumber,
            pageId: page.pageId,
            bookId: storyState.bookId,
            identifier: sorted[0].imageTemplate,
            faceSize: page.faceSize || sorted[0].faceSize,
            facePositioning: page.facePositioning || sorted[0].facePositioning,
          };
        }
      },
    });
  }

  const handleDownloadAll = async () => {
    const imagesToDownload = getImagesByMode({
      storyState,
      generateMode,
      selectedImages,
      filterFn: (page) => page.pageNumber % 2 === 0,
      buildImageData: (pageNumber) => {
        if (pageNumber <= 0) {
          const coverImg = getCoverImage(storyState);
          const sorted = getSortedImages(storyState.coverImages);
          if (!coverImg || !sorted.length) return null;
          return {
            photo_url: sorted[0].imageUrl,
            filename: sorted[0].filename || "cover.jpg",
            page_id: 0,
            book_id: storyState.bookId,
          };
        } else {
          const page = findPageByNumber(storyState.pages, pageNumber);
          if (!page || !page.images) return null;
          const sorted = getSortedImages(page.images);
          if (!sorted.length) return null;

          return {
            photo_url: sorted[0].imageUrl,
            filename: sorted[0].filename || `page_${pageNumber}.jpg`,
            page_id: page.pageId,
            book_id: storyState.bookId,
          };
        }
      },
    });

    if (!imagesToDownload.length) {
      alert("Please select images to download");
      return;
    }

    await withLoading(setIsGenerating, async () => {
      const response = await apiRequest(
        "image/download",
        "POST",
        imagesToDownload
      );
      const blob = await response.blob();

      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download =
        response.headers.get("Content-Disposition")?.split("filename=")[1] ||
        "book_images.zip";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(downloadUrl);

      alert("Images downloaded successfully!");
    });
  };

  const handleGeneratePages = async () => {
    await withLoading(setIsGeneratingPages, async () => {
      const payload = {
        // user_id: storyState.userId || null,
        // user_email: storyState.email,
        user_id: user?.id,
        user_email: user ? user.email : storyState.email,
        request_id: storyState.settings?.userPhotosRequestId,
        age: storyState.settings?.age,
        hairLength: storyState.settings?.hairLength,
        gender: storyState.settings?.gender,
        prompt: getCurrentPagePrompt(),
        bookId: storyState.bookId,
      };

      const response = await apiRequest("image/generatepages", "POST", payload);
      const result = await response.text();
      alert("Pages generated successfully!");
      window.location.reload();
    });
  };

  const handleImageClick = (coords) => {
    console.log("coords", coords);
    setClickedCoords(coords);
  };

  return {
    isGenerating,
    isColorCorrecting,
    isAdjusting,
    isGeneratingPages,
    isGenerateImagePrompt,
    selectedImages,
    generateMode,
    clickedCoords,
    sortedRightImages,
    setSelectedImages,
    setGenerateMode,
    setClickedCoords,
    generateImages,
    generateImagesNewMethod,
    handleColorCorrect,
    handleAdjustImage,
    handlePromptChange,
    handleGenerateImages,
    handleDownloadAll,
    handleGeneratePages,
    regeneratePrompt,
    handleImageClick,
  };
};
