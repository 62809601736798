import React from "react";
import ImageArea from "./ImageArea";
import { BookOpenIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

const BookCover = ({
  isEditable,
  coverImage,
  isGeneratingImages,
  selectedImageIndex,
  onImageClick,
}) => {
  console.log("THE COVER IMAGE: ", coverImage);
  const sortedCoverImages = [
    ...coverImage?.filter((image) => image.is_default),
    ...coverImage?.filter((image) => !image.is_default),
  ];
  return (
    <div className="relativerounded shadow grow flex justify-center items-center w-full h-full">
      <ImageArea
        isEditable={isEditable}
        // passedImages={[coverImage] || []}
        passedImages={sortedCoverImages}
        pageNumber={0}
        isGeneratingImages={isGeneratingImages}
        selectedImageIndex={selectedImageIndex}
        onImageClick={onImageClick}
      />
    </div>
  );
};

export default BookCover;
