import { useState, useEffect } from "react";
import { useStory } from "../context/StoryContext";
import useImageService from "../services/imageService";
import {
  getSortedImages,
  findPageByNumber,
  getPages,
  apiRequest,
} from "utils/bookHelpers";

export const useBookSelection = () => {
  const [selectedImages, setSelectedImages] = useState({});
  const [generateMode, setGenerateMode] = useState("all");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [viewMode, setViewMode] = useState("");

  const { storyState, dispatch } = useStory();
  const imageService = useImageService();

  useEffect(() => {
    setViewMode(storyState.settings?.imageLayout || "double");
  }, [storyState.settings?.imageLayout]);

  const { leftPage, rightPage } = getPages(storyState);

  const sortedCurrentImages = getSortedImages(leftPage.images);
  const sortedRightImages = getSortedImages(rightPage.images);

  const handleImageToggle = (
    pageNumber,
    imageId,
    alternate,
    isCover = false
  ) => {
    const isDoubleLayout = storyState.settings?.imageLayout === "double";

    setSelectedImages((prev) => {
      const newSelectedImages = { ...prev };

      if (isCover) {
        const key = "cover";
        if (newSelectedImages[key]) {
          delete newSelectedImages[key];
        } else {
          newSelectedImages[key] = { pageNumber, imageId, isCover: true };
        }
      } else if (isDoubleLayout && !isCover) {
        let leftKey;
        let rightKey;
        if (alternate) {
          leftKey = `${pageNumber - 1}`;
          rightKey = `${pageNumber}`;
        } else {
          leftKey = `${pageNumber}`;
          rightKey = `${pageNumber + 1}`;
        }

        if (newSelectedImages[leftKey]) {
          delete newSelectedImages[leftKey];
          delete newSelectedImages[rightKey];
        } else {
          const rightPg = storyState.pages?.find(
            (p) => p.pageNumber === +rightKey
          );
          newSelectedImages[leftKey] = { pageNumber, imageId, isCover: false };
          if (rightPg && rightPg.images?.length) {
            newSelectedImages[rightKey] = {
              pageNumber: +rightKey,
              imageId: rightPg.images[0]?.imageId,
              isCover: false,
            };
          }
        }
      } else {
        const key = `${pageNumber}`;
        if (newSelectedImages[key]) {
          delete newSelectedImages[key];
        } else {
          newSelectedImages[key] = { pageNumber, imageId, isCover: false };
        }
      }

      if (Object.keys(newSelectedImages).length > 0) {
        setGenerateMode("selected");
      }

      return newSelectedImages;
    });

    dispatch({
      type: "TOGGLE_IMAGE_SELECTION",
      payload: { pageNumber, imageId, isCover, isDoubleLayout },
    });
  };

  const setDefaultImage = (index, alternate = false) => {
    const isDoubleLayout = storyState.settings?.imageLayout === "double";

    const rightPg =
      storyState.currentPage === 0
        ? { images: storyState.coverImages }
        : findPageByNumber(storyState.pages, storyState.currentPage + 1);

    const sortedRImages = getSortedImages(rightPg?.images || []);

    let imageId;
    if (alternate) {
      imageId = sortedRImages[index]?.imageId;
    } else {
      imageId = sortedCurrentImages[index]?.imageId;
    }

    const pageNumber = storyState.currentPage;
    const referenceId = pageNumber === 0 ? storyState.bookId : leftPage?.pageId;

    const defaultImageData = {
      pageNumber,
      imageId,
      referenceId,
      layout: isDoubleLayout ? "double" : "single",
      rightImageId: isDoubleLayout ? sortedRImages[index]?.imageId : null,
      rightPageId: isDoubleLayout ? rightPg?.pageId : null,
    };

    const response = imageService.setDefaultImage(defaultImageData);

    if (response) {
      dispatch({
        type: "SET_DEFAULT_IMAGE",
        payload: {
          pageNumber,
          imageId,
          rightImageId: isDoubleLayout ? sortedRImages[index]?.imageId : null,
          layout: isDoubleLayout ? "double" : "single",
        },
      });
    }
  };

  const handleSetCover = (imageId, bookId) => {
    imageService.setCover(imageId, bookId, true);
  };

  function imageNavigation(direction, currentIndex, side = "left") {
    console.log("IMAGE NAVIGATION VIEW MODE: ", viewMode);
    const currentPage = storyState.currentPage;
    const isDouble = viewMode === "double";

    let pageImages;
    if (side === "right" && isDouble) {
      pageImages = storyState.pages?.[currentPage]?.images || [];
    } else if (currentPage === 0) {
      pageImages = storyState.coverImages || [];
    } else {
      pageImages = storyState.pages?.[currentPage]?.images || [];
    }

    if (!pageImages.length) return currentIndex;

    let newIndex = currentIndex;
    switch (direction) {
      case "next":
        newIndex = (currentIndex + 1) % pageImages.length;
        break;
      case "prev":
        newIndex = (currentIndex - 1 + pageImages.length) % pageImages.length;
        break;
      case "first":
        newIndex = 0;
        break;
      default:
        break;
    }
    return newIndex;
  }

  const handleImageNavigation = (direction, side = "left") => {
    const newIndex = imageNavigation(direction, selectedImageIndex, side);
    setSelectedImageIndex(newIndex);

    dispatch({
      type: "UPDATE_SELECTED_IMAGE_INDEX",
      payload: { selectedImageIndex: newIndex, side },
    });
  };

  const handleSetDefaultImage = () => {
    if (storyState.currentPage === 0) {
      handleSetCover(
        sortedRightImages[selectedImageIndex]?.imageId,
        storyState.bookId
      );
    } else {
      setDefaultImage(selectedImageIndex, true);
    }
    handleImageNavigation("first", selectedImageIndex);
  };

  const toggleGenerateMode = (mode) => {
    setGenerateMode(mode);
    if (mode === "all") {
      setSelectedImages({});
      dispatch({ type: "CLEAR_ALL_SELECTED_IMAGES" });
    }
  };

  const handleDeleteImage = async () => {
    const imageId = sortedRightImages?.[selectedImageIndex]?.imageId;
    if (!imageId) {
      alert("No image selected to delete");
      return;
    }
    if (sortedRightImages.length === 1) {
      alert("Cannot delete the only image on the page");
      return;
    }

    try {
      const response = await apiRequest(`image/delete/${imageId}`, "DELETE");
      alert("Image deleted successfully");

      const pageNumber =
        storyState.currentPage === 0 ? 0 : storyState.currentPage + 1;
      dispatch({
        type: "REMOVE_IMAGE",
        payload: { pageNumber, imageId },
      });
      // Move selectedImageIndex back if needed
      setSelectedImageIndex((prev) => Math.max(0, prev - 1));
    } catch (error) {
      console.error("Error deleting image:", error);
      alert("Failed to delete image. Please try again.");
    }
  };

  return {
    selectedImages,
    generateMode,
    selectedImageIndex,
    setSelectedImageIndex,
    handleImageToggle,
    handleSetDefaultImage,
    toggleGenerateMode,
    handleImageNavigation,
    handleDeleteImage,
  };
};
