import React from "react";
import { Link } from "react-router-dom";
import {
  PencilIcon,
  BookOpenIcon,
  SparklesIcon,
  GiftIcon,
} from "@heroicons/react/24/outline";
import WhenIGrowUpCover from "../assets/bookcover.png";

const LandingPage = () => {
  return (
    <div className="bg-gradient-to-b from-sky-50 to-white">
      <Hero />
      <FeaturedBooks />
      <HowItWorks />
      <Testimonials />
      <FinalCTA />
    </div>
  );
};

const Hero = () => (
  <div className="relative overflow-hidden">
    <div
      className="absolute inset-0 bg-cover bg-center opacity-10"
      style={{ backgroundImage: `url(${WhenIGrowUpCover})` }}
    />

    <div className="container mx-auto px-4 py-24 relative">
      <div className="max-w-3xl mx-auto text-center">
        <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent">
          Turn Your Child Into a Storybook Hero
        </h1>
        <p className="text-xl md:text-2xl text-gray-600 mb-8">
          Create magical, personalized stories that inspire imagination and
          bring endless smiles
        </p>
        <div className="flex gap-4 justify-center">
          <Link
            to="/create"
            className="px-8 py-4 bg-blue-600 text-white rounded-full font-semibold text-lg shadow-lg hover:bg-blue-500 transform hover:scale-105 transition-all duration-200"
          >
            Create Their Story
          </Link>
          <Link
            to="/examples"
            className="px-8 py-4 bg-white text-blue-600 rounded-full font-semibold text-lg shadow-lg hover:bg-gray-50 transform hover:scale-105 transition-all duration-200"
          >
            See Examples
          </Link>
        </div>
      </div>
    </div>
  </div>
);

const FeaturedBooks = () => {
  const books = [
    {
      id: "when-i-grow-up",
      title: "When I Grow Up",
      cover: WhenIGrowUpCover,
      description: "A magical journey exploring different careers and dreams",
    },
  ];

  return (
    <div className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-4 bg-gradient-to-r from-pink-600 to-purple-600 bg-clip-text text-transparent">
          Featured Stories
        </h2>
        <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
          Discover our collection of personalized adventures that spark
          imagination
        </p>
        <div className="flex justify-center gap-8 flex-wrap">
          {books.map((book) => (
            <div
              key={book.id}
              className="group relative bg-white rounded-2xl shadow-xl overflow-hidden transform hover:scale-105 transition-all duration-300 max-w-sm w-full"
            >
              <div className="aspect-w-4 aspect-h-5 relative">
                <img
                  src={book.cover}
                  alt={book.title}
                  className="w-full h-full object-cover rounded-t-2xl"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/20 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </div>
              <div className="p-6 bg-white relative">
                <h3 className="text-2xl font-bold mb-3 text-gray-800 text-center group-hover:text-purple-600 transition-colors duration-300">
                  {book.title}
                </h3>
                <p className="text-gray-600 text-center mb-4">
                  {book.description}
                </p>
                <Link
                  to={`/personalize/${book.id}`}
                  className="block w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white font-bold py-3 px-6 rounded-full text-center hover:from-blue-500 hover:to-purple-500 transform hover:scale-105 transition-all duration-300 shadow-lg"
                >
                  Personalize This Story
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const HowItWorks = () => {
  const steps = [
    {
      icon: PencilIcon,
      title: "Customize",
      description: "Add your child's name, appearance, and favorite things",
    },
    {
      icon: SparklesIcon,
      title: "Preview",
      description: "See the story come to life with our instant preview",
    },
    {
      icon: BookOpenIcon,
      title: "Personalize",
      description: "Fine-tune the story and illustrations to perfection",
    },
    {
      icon: GiftIcon,
      title: "Deliver",
      description: "Receive a beautiful hardcover book at your doorstep",
    },
  ];

  return (
    <div className="py-20 bg-gradient-to-b from-blue-50 to-purple-50">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-4">
          How It Works
        </h2>
        <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
          Creating your personalized storybook is easy and fun
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step, index) => (
            <div key={index} className="relative">
              {index < steps.length - 1 && (
                <div className="hidden lg:block absolute top-1/4 right-0 w-full h-0.5 bg-blue-200 transform translate-x-1/2" />
              )}
              <div className="bg-white rounded-xl p-6 shadow-lg relative z-10">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mb-4 mx-auto">
                  <step.icon className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="text-xl font-semibold text-center mb-2">
                  {step.title}
                </h3>
                <p className="text-gray-600 text-center">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Testimonials = () => {
  const testimonials = [
    {
      quote:
        "My daughter's face lit up when she saw herself as the main character. Pure magic!",
      author: "Sarah M.",
      role: "Mother of Emma, 6",
    },
    {
      quote:
        "The perfect birthday gift! Our son reads his book every night before bed.",
      author: "Michael P.",
      role: "Father of James, 5",
    },
    {
      quote:
        "The quality of both the story and the book itself exceeded our expectations.",
      author: "Lisa R.",
      role: "Mother of twins",
    },
  ];

  return (
    <div className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
          Parents Love Our Books
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="bg-gray-50 rounded-xl p-8 shadow-lg">
              <div className="flex items-center justify-center mb-6">
                {[...Array(5)].map((_, i) => (
                  <span key={i} className="text-yellow-400 text-2xl">
                    ★
                  </span>
                ))}
              </div>
              <p className="text-gray-600 text-center mb-6 italic">
                "{testimonial.quote}"
              </p>
              <div className="text-center">
                <p className="font-semibold text-gray-800">
                  {testimonial.author}
                </p>
                <p className="text-sm text-gray-500">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const FinalCTA = () => (
  <div className="py-20 bg-gradient-to-r from-blue-600 to-purple-600 text-white">
    <div className="container mx-auto px-4 text-center">
      <h2 className="text-4xl md:text-5xl font-bold mb-6">
        Create Their Next Favorite Story
      </h2>
      <p className="text-xl mb-8 max-w-2xl mx-auto">
        Join thousands of parents who've given their children the gift of
        personalized adventure
      </p>
      <Link
        to="/create"
        className="inline-block px-8 py-4 bg-white text-blue-600 rounded-full font-semibold text-lg shadow-lg hover:bg-gray-50 transform hover:scale-105 transition-all duration-200"
      >
        Start Creating Now
      </Link>
    </div>
  </div>
);

export default LandingPage;
