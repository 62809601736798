import {
  getAccessToken,
  refreshAccessToken,
  setAccessToken,
  getRefreshToken,
} from "../utils/auth";
import config from "../utils/config";

const base_backend = `${config.apiUrl}/api`;
// export const uploadPhotos = async (userId, files) => {
//     const formData = new FormData();
//     formData.append("userId", userId);
//     formData.append("frontal_photo", files[0]);
//     formData.append("profile_photo", files[1]);

//   const token = getAccessToken();
//   const response = await fetch(`${base_backend}/photos/`, {
//     method: "POST",
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//     body: formData,
//   });

//   if (!response.ok) {
//     if (response.status === 401) {
//       const newToken = await refreshAccessToken(getRefreshToken());
//       if (!newToken) {
//         throw new Error("Unable to refresh token");
//       }
//       setAccessToken(newToken);
//       return uploadPhotos(userId, files); // Retry with new token
//     } else {
//       throw new Error("Failed to upload photos");
//     }
//   }

//   return await response.json();
// };

// New function to handle multiple frontal photos
export const uploadMultiplePhotos = async (userId, payload) => {
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("age", payload.age);
  formData.append("gender", payload.gender);

  payload.uploadedPhotos.forEach((photo, index) => {
    formData.append(`frontal_photo_${index + 1}`, photo);
  });

  const token = getAccessToken();
  const response = await fetch(`${base_backend}/photos/multiple_photos`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Failed to upload photos");
  }

  return await response.json();
};

// services/photoService.js

export const uploadPhotosWithoutAccount = async (payload) => {
  const formData = new FormData();
  formData.append("email", payload.email);
  formData.append("age", payload.age);
  formData.append("gender", payload.gender);

  payload.uploadedPhotos.forEach((photo, index) => {
    formData.append(`frontal_photo_${index + 1}`, photo);
  });

  const response = await fetch(`${base_backend}/photos/multiple_photos`, {
    method: "POST",
    body: formData,
  });

  if (!response.ok) {
    throw new Error("Failed to upload photos");
  }

  return await response.json();
};

// export const uploadPhotosWithoutAccount = async (email, frontalPhoto, age, gender) => {
//   const formData = new FormData();
//   formData.append("email", email);
//   formData.append("age", age);
//   formData.append("gender", gender);

//   // Append each frontal photo
//   frontalPhoto.forEach((photo, index) => {
//     formData.append(`frontal_photo_${index + 1}`, photo);
//   });

//   const response = await fetch(`${base_backend}/photos/multiple_photos`, {
//     method: "POST",
//     headers: {
//       // No Authorization header for unauthenticated users
//     },
//     body: formData,
//   });

//   if (!response.ok) {
//     throw new Error("Failed to upload photos");
//   }

//   return await response.json();
// };

// services/photoService.js

export const checkProcessingStatus = async ({ request_id }) => {
  const response = await fetch(`${base_backend}/photos/check_status`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // Add Authorization header if required
    },
    body: JSON.stringify({
      request_id,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to check processing status");
  }

  const data = await response.json();
  return data; // { status: "PROCESSING" } or { status: "PROCESSED" }
};

export const initiateImageGeneration = async ({
  request_id,
  user_id,
  user_email,
  image_folder_uuid,
  book_name,
  age,
  hairLength,
  prompt,
}) => {
  const response = await fetch(`${base_backend}/image/createbook`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      request_id,
      user_id,
      user_email,
      image_folder_uuid,
      book_name,
      age,
      hairLength,
      prompt,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to initiate image generation");
  }

  return await response.json();
};

export const initiateCoverGeneration = async ({
  request_id,
  user_id,
  user_email,
  image_folder_uuid,
  hairDescription,
  personalization,
  name,
  age,
  gender,
}) => {
  const response = await fetch(`${base_backend}/image/generateCover`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      request_id,
      user_id,
      user_email,
      image_folder_uuid,
      hairDescription,
      personalization,
      name,
      age,
      gender,
    }),
  });

  if (!response.ok) {
    throw new Error("Failed to initiate image generation");
  }

  return await response.json();
};

export const uploadPhotos = async (
  userId,
  frontalPhoto,
  profilePhoto,
  age,
  gender
) => {
  const formData = new FormData();
  formData.append("userId", userId);
  formData.append("frontal_photo", frontalPhoto); // The frontal photo file
  formData.append("profile_photo", profilePhoto); // The profile photo file
  formData.append("age", age); // The age input
  formData.append("gender", gender); // The gender input

  const token = getAccessToken();
  const response = await fetch(`${base_backend}/photos/`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`, // Token in header
    },
    body: formData, // FormData containing files and other fields
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return uploadPhotos(userId, frontalPhoto, profilePhoto, age, gender); // Retry with new token
    } else {
      throw new Error("Failed to upload photos");
    }
  }

  return await response.json();
};

export const getPhotos = async (userId) => {
  const token = getAccessToken();
  const response = await fetch(`${base_backend}/photos/`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return getPhotos(userId); // Retry with new token
    } else {
      throw new Error("Failed to fetch photos");
    }
  }

  return await response.json();
};

export const deletePhotoPack = async (requestId) => {
  const token = getAccessToken();
  const response = await fetch(
    `${base_backend}/photos/?requestId=${requestId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    if (response.status === 401) {
      const newToken = await refreshAccessToken(getRefreshToken());
      if (!newToken) {
        throw new Error("Unable to refresh token");
      }
      setAccessToken(newToken);
      return deletePhotoPack(requestId); // Retry with new token
    } else {
      throw new Error("Failed to delete photo pack");
    }
  }

  return await response.json();
};

export const submitPersonalization = async (data) => {
  const response = await fetch(`${base_backend}/photos/submit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error("Failed to submit personalization");
  }

  return await response.json();
};
