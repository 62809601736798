// const compileStoryText = (storyPages) => {
//     return storyPages
//       .map((page, index) => {
//         const pageContent = `Page ${index + 1}\n\n${page.text_blocks
//           .map((block) => block.content)
//           .join("\n")}`;
//         const imagePrompt = page.images.length > 0 ? page.images[0].imagePrompt : "";
//         return imagePrompt
//           ? `${pageContent}\n\nimagePrompt: ${imagePrompt}\n`
//           : `${pageContent}\n`;
//       })
//       .join("\n\n");
//   };

// export {compileStoryText};

export const generateOrderId = () => {
  return 'order-' + Date.now() + '-' + Math.random().toString(36).substr(2, 9);
};

const compileStoryText = (storyPages) => {
  return storyPages
    .map((page, index) => {
      const pageContent = `Page ${index + 1}\n\n${page.text_blocks
        .map((block) => `textBlock: ${block.content}`)
        .join("\n")}`;
      const imagePrompt = page.images.length > 0 ? page.images[0].imagePrompt : "";
      return imagePrompt
        ? `${pageContent}\n\nimagePrompt: ${imagePrompt}\n`
        : `${pageContent}\n`;
    })
    .join("\n\n");
};

const calculateTextBlockBounds = (imageBounds, maxWidthTextBlock) => {
  console.log(imageBounds)
  const bounds = {
    top: imageBounds.top,
    right: imageBounds.right - maxWidthTextBlock.width,
    bottom: imageBounds.bottom,
    left: imageBounds.left,
  };
  return bounds;
};

const calculateBounds = (imageBounds, textBlockBounds) => {
  const bounds = {
    top: 0,
    right: imageBounds.width - textBlockBounds.width - Math.abs(textBlockBounds.left - imageBounds.left),
    bottom: imageBounds.height - textBlockBounds.height,
    left: imageBounds.left - textBlockBounds.left,
  };
  return bounds;
};

export { compileStoryText, calculateBounds, calculateTextBlockBounds};