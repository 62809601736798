import { useState, useRef, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import {
  uploadMultiplePhotos,
  uploadPhotosWithoutAccount,
  initiateCoverGeneration,
  checkProcessingStatus,
  submitPersonalization,
} from "services/photoService";

export function usePersonalizationForm() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const intervalIdRef = useRef(null);

  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [hairDescription, setHairDescription] = useState("");
  const [uploadedPhotos, setUploadedPhotos] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [uploadData, setUploadData] = useState(null);
  const [personalizationMessage, setPersonalizationMessage] = useState("");
  const [isImageGenSubmitting, setIsImageGenSubmitting] = useState(false);
  const [isFirstStepComplete, setIsFirstStepComplete] = useState(false);
  const [requestId, setRequestId] = useState(null);
  const [generationStatus, setGenerationStatus] = useState({
    status: "idle",
    message: "",
    progress: 0,
  });

  const pollStatus = useCallback(
    async (requestId) => {
      try {
        const statusResponse = await checkProcessingStatus({
          request_id: requestId,
        });
        if (statusResponse.status === "PROCESSED") {
          if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
          }
          if (statusResponse.bookId) {
            navigate(`/book/preview/${statusResponse.bookId}`);
          }
        }
      } catch (error) {
        console.error("Error checking processing status:", error);
        if (intervalIdRef.current) {
          clearInterval(intervalIdRef.current);
        }
        alert("Error during processing. Please try again.");
      }
    },
    [navigate]
  );

  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  const onPhotoUpload = async (photos) => {
    if (!validateForm()) return;
    setIsSubmitting(true);
    try {
      const payload = {
        uploadedPhotos: photos,
        age,
        gender,
        email: user?.email || email,
      };
      let response;
      if (user) {
        response = await uploadMultiplePhotos(user.id, payload);
      } else {
        response = await uploadPhotosWithoutAccount(payload);
      }
      setUploadData(response);
      setRequestId(response.requestId);
      setUploadedPhotos(photos);
    } catch (error) {
      console.error("Error uploading photos:", error);
      alert("Failed to upload photos. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const onPersonalizationSubmit = async () => {
    if (!validateForm() && !requestId) {
      alert("Please ensure all fields are filled and photos are uploaded.");
      return;
    }
    setIsImageGenSubmitting(true);
    try {
      const response = await submitPersonalization({
        request_id: requestId,
        user_id: user?.id,
        user_email: user ? user.email : email,
        age,
        gender,
        hairDescription,
        name,
      });

      // Set initial generation status before navigation
      setGenerationStatus({
        status: "generating",
        message: "Generating your personalized book cover...",
        progress: 0,
      });

      if (response.previewUrl) {
        // Pass the generation status through navigation state
        navigate(response.previewUrl, {
          state: {
            generationStatus: "generating",
            requestId: requestId,
          },
        });
      }
    } catch (error) {
      console.error("Error submitting personalization:", error);
      alert("Failed to submit personalization. Please try again.");
    } finally {
      setIsImageGenSubmitting(false);
    }
  };

  const validateForm = () => {
    if (!age || !gender || (!user && !email)) {
      alert(
        "Please fill in all required fields and upload at least one photo."
      );
      return false;
    }
    if (!user && !validateEmail(email)) {
      alert("Please provide a valid email address.");
      return false;
    }
    return true;
  };

  const validateEmail = (emailStr) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(emailStr).toLowerCase());
  };

  return {
    user,
    name,
    setName,
    age,
    setAge,
    gender,
    setGender,
    email,
    setEmail,
    hairDescription,
    setHairDescription,
    personalizationMessage,
    setPersonalizationMessage,
    uploadedPhotos,
    showUploadModal,
    setShowUploadModal,
    isSubmitting,
    isImageGenSubmitting,
    onPhotoUpload,
    onPersonalizationSubmit,
    uploadData,
    isFirstStepComplete,
    setIsFirstStepComplete,
    generationStatus,
    setGenerationStatus,
  };
}
