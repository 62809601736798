export const STORY_ACTIONS = {
  // Story metadata actions
  SET_CURRENT_STORY_ID: "SET_CURRENT_STORY_ID",
  SET_STORY_TEXT: "SET_STORY_TEXT",
  SET_TITLE: "SET_TITLE",
  RESET_STORY: "RESET_STORY",
  SET_STORY_STATE: "SET_STORY_STATE",
  SET_USER_PHOTOS_REQUEST_ID: "SET_USER_PHOTOS_REQUEST_ID",

  // Image related actions
  SET_COVER_IMAGE_PROMPT: "SET_COVER_IMAGE_PROMPT",
  SET_IMAGE_PROMPTS: "SET_IMAGE_PROMPTS",
  ADD_OR_REPLACE_IMAGE: "ADD_OR_REPLACE_IMAGE",
  REMOVE_IMAGE: "REMOVE_IMAGE",
  SET_COVER: "SET_COVER",
  SET_DEFAULT_IMAGE: "SET_DEFAULT_IMAGE",
  TOGGLE_IMAGE_SELECTION: "TOGGLE_IMAGE_SELECTION",
  CLEAR_ALL_SELECTED_IMAGES: "CLEAR_ALL_SELECTED_IMAGES",
  UPDATE_SELECTED_IMAGE_INDEX: "UPDATE_SELECTED_IMAGE_INDEX",
  UPDATE_IMAGE_LAYOUT: "UPDATE_IMAGE_LAYOUT",
  UPDATE_IMAGE_PROMPT: "UPDATE_IMAGE_PROMPT",

  // Page related actions
  ADD_PAGE: "ADD_PAGE",
  DELETE_PAGE: "DELETE_PAGE",
  REORDER_PAGES: "REORDER_PAGES",
  UPDATE_PAGE_NUMBER: "UPDATE_PAGE_NUMBER",
  UPDATE_PAGE_TEXT_BLOCKS: "UPDATE_PAGE_TEXT_BLOCKS",
  ADD_NEW_TEXT_BLOCK: "ADD_NEW_TEXT_BLOCK",
  DELETE_TEXT_BLOCK: "DELETE_TEXT_BLOCK",

  // Face and positioning actions
  UPDATE_FACE_POSITION: "UPDATE_FACE_POSITION",
  UPDATE_ANGLE_RATIO: "UPDATE_ANGLE_RATIO",
  UPDATE_CONTROLNET_STRENGTH: "UPDATE_CONTROLNET_STRENGTH",
  UPDATE_FACE_SIZE: "UPDATE_FACE_SIZE",
  TOGGLE_NEW_SEED: "TOGGLE_NEW_SEED",
  UPDATE_USER_PROMPT: "UPDATE_USER_PROMPT",

  // Background generation actions
  SET_BACKGROUND_GENERATION_STATUS: "SET_BACKGROUND_GENERATION_STATUS",
  SET_BACKGROUND_GENERATION_RESULTS: "SET_BACKGROUND_GENERATION_RESULTS",
};
